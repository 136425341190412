import React from 'react';
import { Typography } from '@mui/material';
//Icons
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

type Props = {
  status?: boolean | null;
  statusDescription?: { status: string, description?: string; };
  text?: [string, string];
  iatStatusId?: number;
};

const StatusIcon = ({ status = null, statusDescription, text, iatStatusId: statusId }: Props) => {
  // Calculate the width based on the length of the longest text
  // const longestText = text ? Math.max(text[0].length, text[1].length) : 0;
  // const spanWidth = longestText / 10 + 1.5; // Adjust the multiplier based on your font size

  return (
    <>
      {status !== null && (
        <>
          {status ? (
            <Typography className='available'>
              <span style={{ width: `auto`, justifyContent: 'center' }}><CheckCircleOutlineOutlinedIcon />
                {text && text[0]}
              </span>
            </Typography>
          ) : (
            <Typography className='unavailable'>
              <span style={{ width: `auto`, justifyContent: 'center' }}><HighlightOffOutlinedIcon />
                {text && text[1]}
              </span>
            </Typography>
          )}
        </>
      )}
      {statusDescription && (
        <>
          {statusDescription.status === 'success' && (
            <Typography className='available'>
              <span><CheckCircleOutlineOutlinedIcon /> {statusDescription.description ?? `Aprovado`}</span>
            </Typography>
          )}
          {statusDescription.status === 'failed' && (
            <Typography className='unavailable'>
              <span><HighlightOffOutlinedIcon /> {statusDescription.description ?? `Reprovado`}</span>
            </Typography>
          )}
          {statusDescription.status === 'warn' && (
            <Typography className='warning'>
              <span><WarningAmberIcon /> {statusDescription.description ?? `Atenção`}</span>
            </Typography>
          )}
          {statusDescription.status === 'pending' && (
            <Typography className='warning'>
              <span><WarningAmberIcon /> {statusDescription.description ?? `Pendente`}</span>
            </Typography>
          )}
        </>
      )}
      {statusId !== undefined && (
        <>
          {statusId === 0 && (
            <Typography className='ignored'>
              <span style={{ width: `auto`, justifyContent: 'center' }}><HorizontalRuleOutlinedIcon />
                {text && text[0]}
              </span>
            </Typography>
          )}
          {statusId === 1 && (
            <Typography className='available'>
              <span style={{ width: `auto`, justifyContent: 'center' }}><CheckCircleOutlineOutlinedIcon />
                {text && text[0]}
              </span>
            </Typography>
          )}
          {(statusId === 2) && (
            <Typography className='unavailable'>
              <span style={{ width: `auto`, justifyContent: 'center' }}><HighlightOffOutlinedIcon />
                {text && text[0]}
              </span>
            </Typography>
          )}
          {statusId === 3 && (
            <Typography className='warning'>
              <span style={{ width: `auto`, justifyContent: 'center' }}><WarningAmberIcon />
                {text && text[0]}
              </span>
            </Typography>
          )}
          {(statusId === -1) && (
            <Typography className='unavailable'>
              <span style={{ width: `auto`, justifyContent: 'center' }}><ErrorOutlineOutlinedIcon />
                {text && text[0]}
              </span>
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default StatusIcon;