import React, { } from 'react';
import TextField, { TextFieldVariants } from '@mui/material/TextField';
import { InputCustom } from '../interfaces/InputCustom';
import { MenuItem } from '@mui/material';

interface Props {
  data: Record<string, any>;
  labelMap: Record<string, InputCustom>;
  handleInputChange: (value: string | number, key: string) => void;
  variant?: TextFieldVariants;
}

const DynamicForm: React.FC<Props> = ({ data, labelMap, handleInputChange, variant }) => {
  // let regexValid = true;

  const validateRegex = (value: string, regexPattern: string): boolean => {
    const regex = new RegExp(regexPattern);
    if (regex.test(value) || value === '') {
      return true;
    } else {
      // regexValid = false;
      return false;
    }
  };

  return (
    <>
      {Object.keys(labelMap).map((key) => {
        const nestedKeys = key.split('.');
        const value = nestedKeys.reduce((obj, nestedKey) => obj && obj[nestedKey], data);
        const label = labelMap[key].label;
        const readOnly = labelMap[key].readonly ?? false;
        const placeHolder = labelMap[key].placeholder ?? '';
        const regexPattern = labelMap[key].regexPattern ?? '';
        const selectOptions = labelMap[key].options;
        const required = labelMap[key].required;

        return (
          <TextField
            key={key}
            label={label}
            value={value ?? ''}
            variant={variant ?? "outlined"}
            margin="normal"
            InputProps={{
              readOnly: readOnly,
            }}
            placeholder={placeHolder}
            required={required}
            error={value !== null && !validateRegex(String(value) ?? '', regexPattern)}
            inputProps={
              regexPattern !== '' ? {
                pattern: regexPattern
              } : {}
            }
            helperText={value !== null && !validateRegex(String(value) ?? '', regexPattern) ? 'Formato inválido' : ''}
            onChange={(e) => {
              if (handleInputChange) {
                e.preventDefault();
                handleInputChange(e.target.value, key);
              }
            }}
            select={!!selectOptions}
          >
            {selectOptions && selectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );
      })}
    </>
  );
};

export default DynamicForm;
