import { useCallback, useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar, ptBR } from '@mui/x-data-grid';

import { trackingHistoricIatRequest } from '../services/apiSys';

import handleError from '../utils/handleError';

import StatusIcon from './StatusIcon';
import { Link } from 'react-router-dom';
import { IatHistoricConfig } from '../interfaces/IatHistoricConfig';
import { Box } from '@mui/system';

type Props = {
  idVantive?: number,
  historic?: IatHistoricConfig[];
};

/**
 * Component to show all the actions executed for a specific ID Vantive
 * @param {number} idVantive The value of ID Vantive to search the historic actions
 * @param {IatHistoricConfig[]} historic The IAT Historic list info
 * @returns 
 */
const IatHistoricActivities = ({ idVantive, historic }: Props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [historicData, setHistoricData] = useState<IatHistoricConfig[] | undefined>(undefined);

  const searchHistoric = useCallback(async () => {
    try {
      setLoading(true);
      setError("");
      const resp = await trackingHistoricIatRequest([{ key: 'idVantive', value: idVantive ?? '' }]);
      // console.log(resp);
      setHistoricData(resp.data ?? []);
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  }, [idVantive]);

  useEffect(() => {
    if (idVantive) {
      setHistoricData(undefined);
      searchHistoric();
    }
    if (historic) {
      setHistoricData(historic);
    }
  }, [idVantive, historic, searchHistoric]);

  const columns: GridColDef<IatHistoricConfig>[] = [
    {
      field: 'id_result',
      headerName: 'ID do Resultado',
      flex: 1,
      renderCell: (params) => (
        <Link to={`/iat/historic/${params.row.id_result}`} target="_blank" >{params.row.id_result}</Link>
      ),
    },
    // {
    //   field: 'created_at', headerName: 'Data de execução', flex: 1, valueGetter: ({ value }) => value && new Date(value).toLocaleString("pt-BR", { timeZone: 'UTC' })
    // },
    {
      field: 'created_at',
      headerName: 'Data de execução',
      flex: 1.5,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(new Date(value).toUTCString().slice(0, -4))
    },
    {
      field: 'updated_at',
      headerName: 'Última Atualização',
      flex: 1.5,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(new Date(value).toUTCString().slice(0, -4))
    },
    { field: 'username', headerName: 'Usuário', flex: 1 },
    {
      field: 'source',
      headerName: 'Sistema',
      flex: 1,
      type: 'singleSelect',
      valueOptions: ['MOBILE', 'WEB']
    },
    {
      field: 'action',
      headerName: 'Tipo de Ação',
      flex: 1,
      type: 'singleSelect',
      valueOptions: ['API', 'LOCAL']
    },
    { field: 'product', headerName: 'Descrição', flex: 1 },
    { field: 'productType', headerName: 'Produto', flex: 1 },
    { field: 'technology', headerName: 'Tecnologia', flex: 1 },
    { field: 'taskType', headerName: 'Operação', flex: 1 },
    {
      field: 'idStatusResult',
      headerName: 'Resultado',
      flex: 1,
      renderCell: (params) => (<StatusIcon status={params.row.resultStatus === 1} text={["ID certificado", "ID não certificado"]} />),
      type: 'singleSelect',
      valueOptions: [
        { value: 1, label: "ID certificado" },
        { value: 2, label: "ID não certificado" }
      ]
    },
  ];

  return (
    <Box marginTop={2} width={'100%'}>
      {historicData && (
        <DataGrid
          autoHeight
          rows={historicData}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            sorting: {
              sortModel: [{ field: 'created_at', sort: 'desc' }]
            }
          }}
          pageSizeOptions={[10, 20, 30]}
          disableColumnSelector
          getRowId={(row) => row.id_result}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          sx={{ '--DataGrid-overlayHeight': '10em' }}
        />
      )}
      {error && <p className='error'>{error}</p>}
      {loading &&
        <Skeleton variant='rounded' width='100%' height={500} />
      }
    </Box>
  );
};

export default IatHistoricActivities;