import { ServiceCharacteristicCallback } from '../interfaces/HpsdConfigCallback';
import { InternetFacility, ServiceCharacteristic } from '../interfaces/InternetFacility';
import { useMemo } from 'react';
import { trackingHistoricHpsdRequest } from '../services/apiSys';
import { HpsdHistoric } from '../interfaces/HpsdHistoric';

const useHpsd = (dataFacility?: InternetFacility) => {

  type HistoricParam = {
    key: string;
    value: string | number;
  };

  /**
    * Get the attributes of Geral Characteristics returned from /tmf-638/facilities
    */
  const geralCharacteristic = useMemo(() => {
    return dataFacility?.serviceCharacteristic?.find((char) => char.name === 'GERAL')?.value;
  }, [dataFacility]);

  /**
   *  Return if the designador type is ERB
   */
  const isErb = useMemo((): boolean => {
    if (geralCharacteristic && geralCharacteristic.TIPO_ACESSO === "ERB") {
      return true;
    }

    return false;
  }, [geralCharacteristic]);

  /**
   * Map and return the serviceCharacteristic of the serviceName searched of the HPSD Facilidade response
   * @param serviceCharacteristic 
   * @param serviceName 
   * @returns 
   */
  const findServiceCharacteristicValue = (
    serviceCharacteristic: ServiceCharacteristic[],
    serviceName: string
  ) => {
    return serviceCharacteristic.find((service) => service.name === serviceName)?.value;
  };

  /**
   * Map and return the serviceCharacteristic of the serviceName searched of the HPSD Callback response
   * @param serviceCharacteristic 
   * @param serviceName 
   * @returns 
   */
  const findServiceCharacteristicCallbackValue = (
    serviceCharacteristic: ServiceCharacteristicCallback[],
    serviceName: string
  ) => {
    return serviceCharacteristic.find((service) => service.name === serviceName)?.value;
  };

  /**
 * Get Historic List by taskType for this designador
 * @param designador 
 * @param taskType 
 * @returns 
 */
  const getHistoricListByTaskType = async (designador: string, taskType: string) => {
    const params = [
      {
        key: 'designador',
        value: designador
      },
      {
        key: 'taskType',
        value: taskType
      }
    ];
    return trackingHistoricHpsdRequest(params);
  };

  /**
   * Check if Last Execution are in Held status
   * @param params 
   * @returns 
   */
  const isHeldStatusForLastExecution = (historicList: HpsdHistoric[]) => {
    if (historicList[0] && historicList[0].status.toLocaleUpperCase() === "HELD") {
      return { id_result: historicList[0].id_result, isHeld: true };
    }

    return { id_result: null, isHeld: false };
  };

  return { geralCharacteristic, findServiceCharacteristicValue, findServiceCharacteristicCallbackValue, isErb, getHistoricListByTaskType, isHeldStatusForLastExecution };


};

export default useHpsd;