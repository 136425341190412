//CSS
import styles from "./Home.module.css";
import "react-multi-carousel/lib/styles.css";
//Assets
import ipLight from "../../assets/images/ip_light.svg";
import dev from "../../assets/images/desenvolvedor.png";
import iat_vivo from "../../assets/images/iat_vivo.svg";
import projeto_fenix from "../../assets/images/projeto_fenix.svg";

//External
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";

type CardType = {
  title?: string;
  description?: string;
  image: string;
  url: string;
};

const cardCarousel: CardType[] = [
  {
    title: "IP LIGHT",
    // description: "Acesse as atividades do IP LIGHT com simplicidade",
    image: ipLight,
    url: "/ip-light"
  },
  {
    title: "IAT Vivo B2B",
    // description: "Realize configurações e ativações de forma simples.",
    image: iat_vivo,
    url: "/iat-hub"
  },
  {
    title: "PROJETO FENIX",
    // description: "Realize configurações e ativações de forma simples.",
    image: projeto_fenix,
    url: ""
  },
  // {
  //   title: "Internet",
  //   description: "Acesse as atividades de Internet com simplicidade",
  //   image: ipInternet,
  //   url: ""
  // },
  {
    title: "Novidades",
    description: "Veja o que acabou de chegar.",
    image: dev,
    url: ""
  },
];

const cardMenu: CardType[] = [
  {
    description: "Fique por dentro das boas práticas de instalações de nossos produtos.",
    image: ipLight,
    url: ""
  },
  {
    description: "Saiba tudo sobre as novas tecnologias.",
    image: ipLight,
    url: ""
  },
  {
    description: "Obtenha insights valiosos para aprimorar a experiência do cliente.",
    image: ipLight,
    url: ""
  },
];

const Header = () => (
  <div className={styles.header}>
    <h1 className={styles.header_title}>Aproveite o melhor do MagicTool.</h1>
    <h2 className={styles.header_subtitle}>Agora para clientes B2B.</h2>
  </div>
);

const CardItem = ({ item }: { item: CardType; }) => (
  <Card className={styles.card}>
    <CardActionArea component={Link} to={item.url}>
      <CardContent>
        {item.title && <Typography className={styles.title}>{item.title}</Typography>}
        {item.description && <Typography variant="h5" className={styles.description}>{item.description}</Typography>}
      </CardContent>
      <CardMedia component="img" alt={item.title} src={item.image} className={styles.icons} />
    </CardActionArea>
  </Card>
);

const responsive = {
  largeDesktop: {
    breakpoint: {
      max: 3000,
      min: 1800,
    },
    items: 4,
    partialVisibilityGutter: 0,
  },
  desktop: {
    breakpoint: {
      max: 1800,
      min: 1024,
    },
    items: 3,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
};

const Home = () => {

  return (
    <div>
      <Header />
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className={styles.carousel}
        draggable={false}
        focusOnSelect={false}
        keyBoardControl
        minimumTouchDrag={80}
        partialVisible
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {cardCarousel.map((item, index) => (
          <CardItem key={index} item={item} />
        ))}
      </Carousel>
      <Grid container spacing={1} alignItems="center" marginTop={0}>
        <Grid item marginLeft={2} xs={12}>
          <h1>Com o MagicTool é diferente. <span style={{ color: '#B4B4B4' }}>Ainda mais motivos para usar o aplicativo.</span></h1>
        </Grid>
        {cardMenu.map((card, index) => (
          <Grid item xs={4} key={index}>
            <CardItem item={card} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Home;
