import styles from './Navbar.module.css';
import logo from "../assets/images/branco.png";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, Typography, AppBar, Container, Toolbar, Box, IconButton, Menu, Button, Backdrop } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
//Context
import { useUserContext } from '../contexts/UserContext';
//Hooks
import useAuthentication from '../hooks/useAuthentication';
//Utils
import { Roles } from '../utils/EnumUtils';
//Components
import MenuDropdown from './MenuDropdown';
import AccountMenu from './AccountMenu';
import DebugSwitch from './DebugSwitch';
//Interfaces
import { IMenuItem } from '../interfaces/MenuItem';

type NavPages = {
  displayName: string;
  uri?: string;
  subMenu?: IMenuItem[];
  allowedRoles?: string[];
};

const produtosFenixMenuItens: IMenuItem[] = [
  {
    category: 'Ativação GPON',
    items: [
      { text: 'Internet Dedicada', link: '/ip-internet', new: true },
    ],
  },
  {
    category: 'Diagnóstico',
    items: [],
  },
  {
    category: 'Serviços',
    items: [
      { text: 'Manobra de Recursos', link: '/manobra/recursos', new: true },
      { text: 'Manobra de IPs', link: '/manobra/ips', new: true },
      { text: 'Manobra de VLAN', link: '/manobra/vlan', new: true },
      { text: 'Manobra de ERB', link: '/manobra/erb', new: true },
      { text: 'Manobra de SWA', link: '/manobra/swa', new: true },
      { text: 'Histórico de operações', link: '/fenix/historic', new: true },
    ],
  },
];

const iatLegadoMenuItens: IMenuItem[] = [
  {
    category: 'Ativação',
    items: [
      // { text: 'Configurar LP', link: '/lp/star' },
      { text: 'Configuração Router', link: '/lp-router/star' },
      { text: 'Check Configuração', link: '/configuration/star' },
      { text: 'Check Programação', link: '/programming/star' },
      { text: 'Check SWT', link: '/swt/star' },
      { text: 'Check OLT', link: '/olt/star' },
      { text: 'Check Duplicidade', link: '/duplicity/star' },
    ],
  },
  {
    category: 'Reparo',
    items: [
      // { text: 'Reconfigurar LP', link: '/lp/iat' },
      { text: 'Reconfiguração Router', link: '/lp-router/iat' },
      { text: 'Check Configuração', link: '/configuration/iat' },
      { text: 'Check Programação', link: '/programming/iat' },
      { text: 'Check SWT', link: '/swt/iat' },
      { text: 'Check OLT', link: '/olt/iat' },
      { text: 'Check Duplicidade', link: '/duplicity/iat' },
    ],
  },
  {
    category: 'Serviços',
    items: [
      { text: 'Consultar ID Vantive', link: '/consultar-vantive' },
      { text: 'Consultar Testes', link: '/iat/historic' },
      { text: 'Produto IP Light', link: '/ip-light', new: true },
    ],
  },
];

const vivo2LegadoMenuItens: IMenuItem[] = [
  {
    category: 'Ativação',
    items: [
      { text: 'Cadastro de Circuitos', link: '/vivo2/instalacao' },
    ],
  },
  {
    category: 'Serviços',
    items: [
      { text: 'Histórico', link: '/vivo2/historic' },
    ],
  },
];

const publicPages: NavPages[] = [{ displayName: 'Login', uri: "login" }, { displayName: 'Cadastre-se', uri: "/cadastro" }];
const protectedPages: NavPages[] = [
  // { displayName: 'Serviços e Produtos', subMenu: serviceItems },
  { displayName: 'Produtos Fênix', subMenu: produtosFenixMenuItens },
  { displayName: 'Produtos Vivo1 Legado', subMenu: iatLegadoMenuItens },
  { displayName: 'Produtos Vivo2 Legado', subMenu: vivo2LegadoMenuItens },
  { displayName: "Suporte", uri: "/suporte", allowedRoles: [Roles.admin, Roles.avancado, Roles.suporte] },
];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [pagesLoggedFiltered, setPagesLoggedFiltered] = useState<NavPages[]>(protectedPages);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { isAuthenticated, user, userHasRole } = useUserContext();

  const { logout } = useAuthentication();
  // const handleLogout = (): void => {
  //   logout(user);
  // };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const menuItemBuild = (itens: NavPages[]) => {
    return (
      itens.map((page, index) => !page.subMenu ? (
        <MenuItem key={index} onClick={handleCloseNavMenu} component={Link} to={page.uri!}>
          <Typography textAlign="center">{page.displayName}</Typography>
        </MenuItem>
      ) : (
        <MenuDropdown key={index} displayName={page.displayName} menuItems={page.subMenu} onMenuOpen={handleMenuOpen} onMenuClose={handleMenuClose} />
      ))
    );
  };

  useEffect(() => {
    //Filter the protectedPages list by the user role
    if (isAuthenticated) {
      if (!userHasRole(Object.values(Roles))) {
        setPagesLoggedFiltered([]);
      } else {
        setPagesLoggedFiltered(protectedPages.filter((page) => {
          // Check if the page has no specified allowedRoles or if the user role is included in the allowedRoles array
          return !page.allowedRoles || userHasRole(page.allowedRoles);
        }));
      }
    }
  }, [isAuthenticated, userHasRole]);

  return (
    <>
      <AppBar className={styles.nav} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            {/* Tela Menor */}
            <Typography
              variant="h6"
              noWrap
              component={Link} to="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img className='logo_img' src={logo} alt="logomagic" />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {isAuthenticated
                  ? menuItemBuild(pagesLoggedFiltered)
                  : menuItemBuild(publicPages)
                }
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
            {/* Tela Maior */}
            <Typography
              variant="h5"
              noWrap
              component={Link} to="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img className='logo_img' src={logo} alt="logomagic" />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {isAuthenticated ? pagesLoggedFiltered.map((page, index) => !page.subMenu ? (
                <Button
                  key={index}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  component={Link} to={page.uri!}
                >
                  {page.displayName}
                </Button>
              ) : (
                <MenuDropdown key={index} displayName={page.displayName} menuItems={page.subMenu} onMenuOpen={handleMenuOpen} onMenuClose={handleMenuClose} />
              ))
                :
                publicPages.map((page, index) => (
                  <Button
                    key={index}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                    component={Link} to={page.uri!}
                  >
                    {page.displayName}
                  </Button>
                ))}
            </Box>

            <Box>
              <DebugSwitch />
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {isAuthenticated &&
                <div className={styles.user_profile_container}>
                  {user ? (
                    <AccountMenu userName={user.name} handleLogout={logout} />
                  ) : (
                    <AccountMenu userName="" handleLogout={logout} />
                  )}

                </div>
              }
            </Box>
          </Toolbar>
        </Container>
      </AppBar >
      <Toolbar />
      {/* Makes the elements bellow appbar with a background black with opacity */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isMenuOpen}
      />
    </>
  );
}
export default Navbar;
