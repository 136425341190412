import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InternetFacility, Value } from '../interfaces/InternetFacility';
import { Alert, Backdrop, Box, Button, Card, CardContent, CardHeader, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import DynamicForm from './DynamicForm';
import DynamicView from './DynamicView';
import { HpsdConfigBodyRequest } from '../interfaces/HpsdConfigBodyRequest';
import { hpsdConfigCallbackRequest, hpsdConfigRequest } from '../services/apiSys';
import handleError from '../utils/handleError';
import { InputCustom } from '../interfaces/InputCustom';
import CircularProgressIndeterminateWithLabel from './CircularProgressIndeterminateWithLabel';
import { ServiceCharacteristicCallback, ValueElement } from '../interfaces/HpsdConfigCallback';
import useHpsd from '../hooks/useHpsd';

type Props = {
  dataFacility: InternetFacility;
};

type GenericField = {
  name: string;
  value: string;
};

type Slot = {
  type: string;
  idSlot: string;
  nomeSlot: string;
  statusSlot: string;
  portas: Porta[];
};

type Porta = {
  type: string;
  idPorta: string;
  nomePorta: string;
  statusPorta: string;
  interfaceUpLink: string;
  tipoInterfaceUpLink: string;
  interfaceCliente: string;
  tipoInterfaceCliente: string;
  velocidadePorta: string;
  idAccess?: string;
  hostnameSWT?: string;
  cvlan?: string;
};

type AvailableSlotRow = {
  idSwa: string;
  idPortaSwa: string;
  hostnameSwa: string | undefined;
  nomeSlot: string;
  nomePorta: string;
};

const erbAcessoLabelDisplay: Record<string, InputCustom> = {
  'HOSTNAME_SWA': { label: 'HOSTNAME SWA', mapRequestName: 'HOSTNAME_SWA_PARA', required: true },
  'SLOTE': { label: 'NOME SLOTE', mapRequestName: 'SLOT_SWA_PARA', required: true },
  'PORTA': { label: 'NOME PORTA', mapRequestName: 'PORTA_SWA_PARA', required: true },
  'SIGLA_ERB': { label: 'ERB', mapRequestName: 'ERB', readonly: true },
};

const erbDisplayMNSWA07: { key: string; displayName: string; }[] = [
  { key: 'hostnameSwa', displayName: 'HOSTNAME SWA' },
  { key: 'swaAdaptavel', displayName: 'Adaptável' },
  { key: 'rede', displayName: 'REDE' },
  { key: 'quantidadePortasLivres', displayName: 'Quantidade de Portas Livres' },
  { key: 'quantidadeCvlanLivres', displayName: 'Quantidade CVLAN Livres' },
  { key: 'velocidadeBandaDisponivel', displayName: 'Velocidade Banda Disponível' }
];

const motives = [
  'Porta Ocupada',
  'Rede Saturada',
  'Distância',
  'Porta com defeito',
  'Acerto de Cadastro',
  'Alívio de Rede',
];

/** Format the data for the request of /tmf-641 API */
const formatRequestFields = (data: InternetFacility, mapping: Record<string, InputCustom>, serviceName: string): GenericField[] => {
  const characteristics = data.serviceCharacteristic.find((service) => service.name === serviceName)?.value;
  let fields: any = [];

  Object.keys(mapping).forEach((key) => {
    if (characteristics && characteristics.hasOwnProperty(key)) {
      fields = [...fields, { name: mapping[key].mapRequestName, value: characteristics[key as keyof Value] }];
    }
  });

  return fields;
};

const ManobraSwaDisplay = ({ dataFacility }: Props) => {

  const [dataFacilityUpdated, setDataFacilityUpdated] = useState<InternetFacility>(dataFacility);
  const [motive, setMotive] = useState<string>('');
  const [resultId, setResultId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [warning, setWarning] = useState<string>('');
  const notificationRef = useRef<HTMLDivElement>(null);
  const [availableMNSWA07Fields, setAvailableMNSWA07Fields] = useState<ValueElement[] | undefined>();
  const [selectedMNSWA07Row, setSelectedMNSWA07Row] = useState<number | null>(null);
  const [availableMNSWA18Fields, setAvailableMNSWA18Fields] = useState<AvailableSlotRow[]>();
  const [selectedMNSWA18Row, setSelectedMNSWA18Row] = useState<number | null>(null);
  const [responseCallbackMNSWA18, setResponseCallbackMNSWA18] = useState<ValueElement[]>();

  const { geralCharacteristic } = useHpsd(dataFacility);

  /**
   * Get the attributes of Geral Characteristics returned from /tmf-638/facilities
   */
  // const geralCharacteristic = getCharacteristic('GERAL');

  /**
   * Get the attributes of ERB Acesso  Characteristics returned from /tmf-638/facilities
   * Get it's index to update the data in the dataFacilityUpdated
   */
  const { erbAcessoIndexOf, erbAcessoCharacteristic }: { erbAcessoIndexOf: number; erbAcessoCharacteristic: Value; } = useMemo(() => {
    let index = dataFacility.serviceCharacteristic.findIndex((service) => service.name === "ERB.ACESSO");
    return { erbAcessoIndexOf: index, erbAcessoCharacteristic: dataFacility.serviceCharacteristic[index].value };
  }, [dataFacility]); // Invoking the function immediately to get the initial values


  /**
   * Scroll to the notification
   */
  useEffect(() => {
    if (notificationRef.current) {
      notificationRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, [error, success, warning]);

  /**
 * Execute Manobra SWA via request API /tmf-641 category MNSWA14
 * @param idPortaSWA 
 * @param hostnameSwa 
 * @param allHostnameSwt 
 */
  const executeManobraMNSWA14 = useCallback(async (idPortaSWA: string, hostnameSwa: string, allHostnameSwt: string[]) => {
    // clearMsgs();
    try {
      setLoading(true);
      const body: HpsdConfigBodyRequest = {
        service: {
          id: dataFacility.id,
          category: "ManobraRecursos.MNSWA14",
          serviceSpecification: "Internet"
        },
        fields: [
          { name: "ID_PORTA_SWA", value: idPortaSWA },
          { name: "HOSTNAME_SWA", value: hostnameSwa },
          { name: "ALL_HOSTNAMES_SWT_ERB", value: allHostnameSwt },
          { name: "NaturezaOrdem", value: "Manobra" },
          { name: 'REALLOCATION_REASON', value: motive },
        ]
      };
      const resp = await hpsdConfigRequest(body);
      setResultId(resp.data?.id_result);
    } catch (error) {
      handleError({ error, setError });
    }
  }, [dataFacility.id, motive]);

  /**
 * Search available Resources via API /tmf-641 MNSWA07
 */
  const executeSearchMNSWA07 = useCallback(async () => {
    try {
      setLoading(true);
      const body: HpsdConfigBodyRequest = {
        service: {
          id: dataFacility.id,
          category: "ManobraRecursos.MNSWA07",
          serviceSpecification: "Internet"
        },
        fields: [
          { name: "NaturezaOrdem", value: "Manobra" },
        ]
      };
      const resp = await hpsdConfigRequest(body);
      setResultId(resp.data?.id_result);
    } catch (error) {
      handleError({ error, setError });
    }
  }, [dataFacility.id]);

  /**
* Validate the Callback Response of MNSWA05 and check if the resource is available or not
* @param serviceCharacteristic 
*/
  const validateMNSWA05CallbackResponse = useCallback((serviceCharacteristic: ServiceCharacteristicCallback[]) => {
    const resource = serviceCharacteristic.find((service) => service.name === "resources")?.value as ValueElement;

    if (resource.state === "available") {
      setSuccess('Posição disponível. Realizando Manobra.');

      const allHostnameSwt = resource.characteristic.find((resource) => resource.name === "allHostnamesSwtErb")?.value as unknown as string[];

      // Get user edited fields
      const fields = formatRequestFields(dataFacilityUpdated, erbAcessoLabelDisplay, "ERB.ACESSO");
      const newHostname = fields.find((field) => field.name === "HOSTNAME_SWA_PARA")?.value;
      const newSlot = fields.find((field) => field.name === "SLOT_SWA_PARA")?.value;
      const newPorta = fields.find((field) => field.name === "PORTA_SWA_PARA")?.value;

      //Get IdPorta 
      const slots = resource.characteristic.find((resource) => resource.name === "slots")?.value as unknown as Slot[];
      const portas = slots.find((slot) => slot.nomeSlot === newSlot)?.portas as unknown as Porta[];
      const idPorta = portas.find((porta) => porta.nomePorta === newPorta)?.idPorta;

      // Execute Manobra MNSWA14
      executeManobraMNSWA14(idPorta!, newHostname!, allHostnameSwt!);
    } else {
      setWarning('Posição indisponível. Consultando posições disponíveis.');

      // Execute Search MNSWA07
      executeSearchMNSWA07();
    }
  }, [dataFacilityUpdated, executeManobraMNSWA14, executeSearchMNSWA07]);

  //Method to ping the Hpsd Config Callback
  const pingConfigStatus = useCallback(async (resultId: string, intervalId?: NodeJS.Timer) => {
    try {
      const resp = await hpsdConfigCallbackRequest(resultId);
      if (resp.data && Object.keys(resp.data).length > 0) {
        // If the config is finished, clear the interval
        clearInterval(intervalId);
        if (resp.data.event.serviceOrder.serviceOrderItem) {
          const service = resp.data.event.serviceOrder.serviceOrderItem[0].service;

          if (service.category === 'ManobraRecursos.MNSWA05') {
            // const statusManobra = resp.data.event.serviceOrder.state;
            validateMNSWA05CallbackResponse(service.serviceCharacteristic);
          }
          else if (service.category === 'ManobraRecursos.MNSWA07') {
            clearMsgs();
            const availableResources = filterAvailableResources(service.serviceCharacteristic.find((service) => service.name === 'resources')?.value as ValueElement[]);
            if (availableResources.length >= 1) {
              setSuccess('Campos disponíveis encontrados.');
              setAvailableMNSWA07Fields(availableResources);
            } else {
              setWarning('Nenhum campo disponível foi encontrado');
            }
            setLoading(false);
          }
          else if (service.category === 'ManobraRecursos.MNSWA18') {
            const resources = service.serviceCharacteristic.find((service) => service.name === 'resources')?.value as ValueElement[];
            setResponseCallbackMNSWA18(resources);
            const availableResources = filterAvailableSlots(resources);
            if (availableResources.length >= 1) {
              setSuccess('Posições disponíveis encontrados.');
              setAvailableMNSWA18Fields(availableResources);
            } else {
              setWarning('Nenhuma posição disponível foi encontrado');
            }
            setLoading(false);
          }
          else if (service.category === 'ManobraRecursos.MNSWA14') {
            clearMsgs();
            const status = service.serviceCharacteristic.find((service) => service.name === "STATUS_MANOBRA")?.value;
            if (status && status.toString().toLowerCase() === 'success') {
              setSuccess('Manobra realizada com sucesso.');
            } else {
              setError(resp.data.event.serviceOrder.description ?? 'Manobra finalizada com erro.');
            }
            setLoading(false);
          }
        } else {
          setError('Timeout - não recebeu resposta do callback HPSD.');
          setLoading(false);
        }

        return resp.data;
      }
    } catch (error) {
      handleError({ error, setError });
      clearInterval(intervalId);
      setLoading(false);
      // setResultId('');
    }
  }, [validateMNSWA05CallbackResponse]);

  /**
   * Request callback response from the config
   */
  useEffect(() => {
    if (resultId) {

      // Set up a periodic ping every 5 seconds to check if the Config Task is already finished and get the result
      const intervalId = setInterval(async () => {
        // Check task status initially
        pingConfigStatus(resultId, intervalId);
      }, 5000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [resultId, pingConfigStatus]);

  /**
   * Updated the dataFacilityUpdated with the changes made by the user
   * @param value 
   * @param key 
   */
  const handleInputChangeForm = (value: string | number, key: string) => {
    const updatedServiceCharacteristic = [...dataFacilityUpdated.serviceCharacteristic]; // Create a copy of the array
    updatedServiceCharacteristic[erbAcessoIndexOf] = {
      ...updatedServiceCharacteristic[erbAcessoIndexOf],
      value: {
        ...updatedServiceCharacteristic[erbAcessoIndexOf].value,
        [key]: value
      }
    };
    setDataFacilityUpdated({
      ...dataFacility,
      serviceCharacteristic: updatedServiceCharacteristic
    });
  };

  /**
   * Clear all status messages
   */
  const clearMsgs = () => {
    setError('');
    setSuccess('');
    setWarning('');
  };

  /**
   * Filter Resources with status 'available'
   * @param resources
   * @returns 
   */
  const filterAvailableResources = (resources: ValueElement[]) => {
    let availableResources: ValueElement[] = [];
    if (resources) {
      availableResources = resources.filter((resource) => resource.state === "available");
    }
    return availableResources;
  };

  /**
   * Filter Slots with status 'available' and the Portas with status 'available'
   * @param resources 
   * @returns
   */
  const filterAvailableSlots = (resources: ValueElement[]) => {
    const availableSlots = resources
      .flatMap((resource) =>
        resource.characteristic
          .filter(char => char.name === "slots") // Pegar o characteristic chamado 'slots'
          .flatMap(char => char.value as unknown as Slot[])           // Acessar os slots
          .filter((slot) => slot.statusSlot === "available") // Filtrar slots disponíveis
          .flatMap((slot) =>
            slot.portas
              .filter(porta => porta.statusPorta === "available") // Filtrar portas disponíveis
              .map(porta => ({
                idSwa: resource.id,
                idPortaSwa: porta.idPorta,
                hostnameSwa: resource.characteristic.find(char => char.name === "hostnameSwa")?.value,
                nomeSlot: slot.nomeSlot,
                nomePorta: porta.nomePorta
              }))
          )
      );

    return availableSlots;
  };

  /**
   * Search available Slots and Ports via API /tmf-641 MNSWA18
   * @param idSwa 
   */
  const executeSearchMNSWA18 = async (idSwa: string) => {
    clearMsgs();
    try {
      setLoading(true);
      const body: HpsdConfigBodyRequest = {
        service: {
          id: dataFacility.id,
          category: "ManobraRecursos.MNSWA18",
          serviceSpecification: "Internet"
        },
        fields: [
          { name: "ID_SWA_PARA", value: idSwa },
          { name: "NaturezaOrdem", value: "Manobra" },
        ]
      };
      const resp = await hpsdConfigRequest(body);
      setResultId(resp.data?.id_result);
    } catch (error) {
      handleError({ error, setError });
    }
  };



  /** Handle button to request MNSWA05 in HPSD */
  const handleSubmitMNSWA05 = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setResultId('');
    clearMsgs();

    try {
      if (dataFacilityUpdated) {
        setLoading(true);
        const fields = formatRequestFields(dataFacilityUpdated, erbAcessoLabelDisplay, "ERB.ACESSO");
        const body: HpsdConfigBodyRequest = {
          service: {
            id: dataFacility.id,
            category: "ManobraRecursos.MNSWA05",
            serviceSpecification: "Internet"
          },
          fields: [
            ...fields,
            { name: "NaturezaOrdem", value: "Manobra" }
          ]
        };
        const resp = await hpsdConfigRequest(body);
        setResultId(resp.data?.id_result);
      }
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  /**
   * Handle button to request MNSWA18 in HPSD
   */
  const handleSubmitMNSWA18 = async () => {
    clearMsgs();
    try {
      setLoading(true);
      if (selectedMNSWA07Row === null) {
        setError('Selecione a posição desejada.');
      }
      else if (availableMNSWA07Fields) {
        executeSearchMNSWA18(availableMNSWA07Fields[selectedMNSWA07Row].id);
      }
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  /**
 * Handle button to request MNSWA14 in HPSD
 */
  const handleSubmitMNSWA14 = async () => {
    clearMsgs();
    try {
      if (availableMNSWA18Fields && selectedMNSWA18Row && responseCallbackMNSWA18) {
        setLoading(true);
        const idPortaSwa = availableMNSWA18Fields[selectedMNSWA18Row].idPortaSwa;
        const idSwa = availableMNSWA18Fields[selectedMNSWA18Row].idSwa;
        const resource = responseCallbackMNSWA18.find((resource) => resource.id === idSwa);
        if (resource) {
          const hostnameSwa = resource.characteristic.find((resource) => resource.name === "hostnameSwa")?.value;
          const allHostnameSwt = resource.characteristic.find((resource) => resource.name === "allHostnamesSwtErb")?.value as unknown as string[];
          executeManobraMNSWA14(idPortaSwa, hostnameSwa!, allHostnameSwt!);
        }

      }
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  /**
  * Handle the change in options of the available fields returned from /tmf-641 category MNSWA07
  * @param event 
  */
  const handleMNSWA07RadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMNSWA07Row(parseInt(event.target.value));
  };

  /**
  * Handle the change in options of the available fields returned from /tmf-641 category MNSWA18
  * @param event 
  */
  const handleMNSWA18RadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMNSWA18Row(parseInt(event.target.value));
  };

  return (
    <Box width={'100%'}>
      <Stack sx={{ width: '100%', marginTop: 1 }} spacing={2}>
        <div ref={notificationRef}>
          {error && (
            <Alert severity="error" variant='filled' onClose={() => setError('')}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" variant='filled' onClose={() => setSuccess('')}>
              {success}
            </Alert>
          )}
          {warning && (
            <Alert severity="warning" variant='filled' onClose={() => setWarning('')}>
              {warning}
            </Alert>
          )}
        </div>
      </Stack>
      {geralCharacteristic && erbAcessoCharacteristic && (
        <Box
          width="100%"
          marginTop={1}
          component="form"
          onSubmit={handleSubmitMNSWA05}
        >
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Card>
                <CardContent sx={{
                  '& .MuiTextField-root': { m: 1 },
                  display: 'flex',
                  justifyContent: 'space-around'
                }}>
                  <TextField
                    label="Tipo Acesso"
                    value={geralCharacteristic.TIPO_ACESSO}
                    variant='standard'
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                  />
                  <TextField
                    label="Rede"
                    value={geralCharacteristic.PLACE_RESERVED?.network_owner}
                    variant='standard'
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                  />
                  <TextField
                    label="Serviço"
                    value={geralCharacteristic.TIPO_SERVICO}
                    variant='standard'
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                  />
                  <TextField
                    label="Razão Social"
                    value={geralCharacteristic.RAZAO_SOCIAL}
                    variant='standard'
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                  />
                </CardContent>
              </Card>
            </Grid>
            {!availableMNSWA07Fields && (
              <>
                <Grid item xs={6} display={'grid'}>
                  <Card className='card'>
                    <CardHeader
                      title="Posição Atual"
                      titleTypographyProps={{ textAlign: "center" }}
                    />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                      <DynamicView
                        data={erbAcessoCharacteristic}
                        labelMap={erbAcessoLabelDisplay}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} display={'grid'}>
                  <Card className='card'>
                    <CardHeader
                      title="Posição Desejada"
                      titleTypographyProps={{ textAlign: "center" }}
                    />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                      <DynamicForm
                        data={dataFacilityUpdated.serviceCharacteristic[erbAcessoIndexOf].value}
                        labelMap={erbAcessoLabelDisplay}
                        handleInputChange={handleInputChangeForm}
                      />
                      <FormControl fullWidth margin='normal'>
                        <InputLabel>Motivo</InputLabel>
                        <Select
                          value={motive}
                          label="Motivo"
                          required
                          onChange={(e) => setMotive(e.target.value as string)}
                        >
                          {motives.map((motive) => (
                            <MenuItem
                              key={motive}
                              value={motive}
                            >
                              {motive}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    className='button'
                    type='submit'
                    variant="contained"
                    disabled={loading} // || compare(dataFacility, dataFacilityUpdated)
                  >
                    {loading ? `Verificando...` : `Verificar Disponibilidade`}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      )}
      {availableMNSWA07Fields && (
        <Box
          width="100%"
          marginTop={1}
        >
          <TableContainer component={Paper} sx={{ marginTop: 1 }}>
            <Typography variant='h6' textAlign={'center'}>
              Posição Desejada
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  {erbDisplayMNSWA07.map((item, index) => (
                    <TableCell key={index}>{item.displayName}</TableCell>
                  ))}
                  <TableCell>Selecione</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {availableMNSWA07Fields.map((row, rowIndex) => (
                  <TableRow key={row.id}>
                    {erbDisplayMNSWA07.map((item, cellIndex) => {
                      const charItem = row.characteristic.find(char => char.name === item.key);
                      return (
                        <TableCell key={cellIndex}>{charItem ? charItem.value as string : ''}</TableCell>
                      );
                    })}
                    <TableCell>
                      <RadioGroup value={selectedMNSWA07Row} onChange={handleMNSWA07RadioChange}>
                        <FormControlLabel label={''} value={rowIndex} control={<Radio />} />
                      </RadioGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display={'flex'}
            justifyContent={'center'}
            marginTop={1}
          >
            <Button
              className='button'
              onClick={handleSubmitMNSWA18}
              variant="contained"
              disabled={loading}
            >
              {`Pesquisar`}
            </Button>
          </Box>
        </Box>
      )}
      {availableMNSWA18Fields && (
        <Box
          width="100%"
          marginTop={1}
        >
          <TableContainer component={Paper} sx={{ marginTop: 1 }}>
            <Typography variant='h6' textAlign={'center'}>
              Posição Desejada
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Hostname SWA</TableCell>
                  <TableCell>Nome Slot</TableCell>
                  <TableCell>Nome Porta</TableCell>
                  <TableCell>Selecione</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {availableMNSWA18Fields.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell >{row.hostnameSwa}</TableCell>
                    <TableCell >{row.nomeSlot}</TableCell>
                    <TableCell >{row.nomePorta}</TableCell>
                    <TableCell>
                      <RadioGroup value={selectedMNSWA18Row} onChange={handleMNSWA18RadioChange}>
                        <FormControlLabel label={''} value={index} control={<Radio />} />
                      </RadioGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display={'flex'}
            justifyContent={'center'}
            marginTop={1}
          >
            <Button
              className='button'
              onClick={handleSubmitMNSWA14}
              variant="contained"
              disabled={loading}
            >
              {`Manobrar`}
            </Button>
          </Box>
        </Box>
      )}
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
          open={loading}
        >
          {/* <CircularProgress color="inherit" /> */}
          {/* <CircularWithValueLabel maxSecondsTime={180} interval={5000} /> */}
          <CircularProgressIndeterminateWithLabel maxSecondsTime={600} descriptions={
            ['Processo em andamento...', 'Processo demorando mais que o normal...', 'Provável falha na consulta por timeout...']
          }
          />
        </Backdrop>
      )}
    </Box>
  );
};

export default ManobraSwaDisplay;






