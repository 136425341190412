import { ChangeEvent, FormEvent, useState } from 'react';
import { IVivo2Circuit } from '../interfaces/IVivo2Circuit';
import { Grid, TextField, Button, Box, MenuItem } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { IPV4MaskInput, Ipv4CidrRegex, Ipv6CidrRegex } from '../utils/MaskUtils';
import FormGroups from './FormGroups';

type Props = {
  installation?: IVivo2Circuit,
  onSave: (installation: IVivo2Circuit) => void;
};

const speedLinks = [
  {
    value: '300 Mbps',
    label: '300 Mbps'
  },
  {
    value: '400 Mbps',
    label: '400 Mbps'
  },
  {
    value: '500 Mbps',
    label: '500 Mbps'
  },
  {
    value: '1 Gbps',
    label: '1 Gbps'
  },
  {
    value: '2 Gbps',
    label: '2 Gbps'
  },
];

const categorias = ['DEDICADO', 'LIGHT'];
const serviceTypes = ['IP DEDICADO', 'VPN IP', 'VPN IP MPLS', 'IP MULTI SERVIÇO', 'SIP TRUNKING PORT'];

const Vivo2CircuitForm = ({ installation, onSave }: Props) => {
  const initialFormState = {
    dataAgendamento: installation?.data_agendamento ? dayjs(installation.data_agendamento) : null,
    cliente: installation?.cliente || '',
    endCliente: installation?.end_cliente || '',
    categoria: installation?.categoria || '',
    servico: installation?.servico || '',
    equipamentos: installation?.equipamentos || '',
    osTbs: installation?.os_tbs || '',
    designador: installation?.designador || '',
    idVantive: installation?.id_vantive || '',
    velocidade: installation?.velocidade || '',
    ipv4LanNetworkAddress: installation?.ipv4LanNetworkAddress || '',
    ipv4LanBroadcastAddress: installation?.ipv4LanBroadcastAddress || '',
    ipv4LanCidr: installation?.ipv4LanCidr || '',
    ipv4LanRouterInterface: installation?.ipv4LanRouterInterface || '',
    ipv4LanFirstClientAddress: installation?.ipv4LanFirstClientAddress || '',
    ipv4LanLastClientAddress: installation?.ipv4LanLastClientAddress || '',
    ipv4WanNetworkAddress: installation?.ipv4WanNetworkAddress || '',
    ipv4WanBroadcastAddress: installation?.ipv4WanBroadcastAddress || '',
    ipv4WanCidr: installation?.ipv4WanCidr || '',
    ipv4WanRouterInterface: installation?.ipv4WanRouterInterface || '',
    ipv4WanVivoRouterInterface: installation?.ipv4WanVivoRouterInterface || '',
    ipv6LanNetworkAddress: installation?.ipv6LanNetworkAddress || '',
    ipv6LanBroadcastAddress: installation?.ipv6LanBroadcastAddress || '',
    ipv6LanCidr: installation?.ipv6LanCidr || '',
    ipv6LanRouterInterface: installation?.ipv6LanRouterInterface || '',
    ipv6LanFirstClientAddress: installation?.ipv6LanFirstClientAddress || '',
    ipv6LanLastClientAddress: installation?.ipv6LanLastClientAddress || '',
    ipv6WanNetworkAddress: installation?.ipv6WanNetworkAddress || '',
    ipv6WanBroadcastAddress: installation?.ipv6WanBroadcastAddress || '',
    ipv6WanCidr: installation?.ipv6WanCidr || '',
    ipv6WanRouterInterface: installation?.ipv6WanRouterInterface || '',
    ipv6WanVivoRouterInterface: installation?.ipv6WanVivoRouterInterface || '',
    ipLoopbackNetworkAddress: installation?.ipLoopbackNetworkAddress || '',
    ipLoopbackCidr: installation?.ipLoopbackCidr || '',
    gerenciaEdd: installation?.gerencia_edd || '',
    contato: installation?.contato || '',
    acesso: installation?.acesso || '',
    armario: installation?.armario || '',
    vendor: installation?.vendor || '',
    cvlan: installation?.cvlan || '',
    svlan: installation?.svlan || '',
    vlanGerencia: installation?.vlan_gerencia || '',
    description: installation?.description || '',
  };

  const [formState, setFormState] = useState(initialFormState);
  const [errors, setErrors] = useState<{ [key: string]: string; }>({});

  const validateField = (name: string, value: any) => {
    let error = '';

    const validators: { [key: string]: (value: any) => boolean; } = {
      ipv4LanNetworkAddress: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4LanBroadcastAddress: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4LanRouterInterface: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4LanFirstClientAddress: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4LanLastClientAddress: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4WanNetworkAddress: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4WanBroadcastAddress: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4WanRouterInterface: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4WanVivoRouterInterface: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipLoopbackNetworkAddress: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv6LanNetworkAddress: value => value !== '' ? Ipv6CidrRegex.test(value) : true,
      ipv6LanBroadcastAddress: value => value !== '' ? Ipv6CidrRegex.test(value) : true,
      ipv6LanRouterInterface: value => value !== '' ? Ipv6CidrRegex.test(value) : true,
      ipv6LanFirstClientAddress: value => value !== '' ? Ipv6CidrRegex.test(value) : true,
      ipv6LanLastClientAddress: value => value !== '' ? Ipv6CidrRegex.test(value) : true,
      ipv6WanNetworkAddress: value => value !== '' ? Ipv6CidrRegex.test(value) : true,
      ipv6WanBroadcastAddress: value => value !== '' ? Ipv6CidrRegex.test(value) : true,
      ipv6WanRouterInterface: value => value !== '' ? Ipv6CidrRegex.test(value) : true,
      ipv6WanVivoRouterInterface: value => value !== '' ? Ipv6CidrRegex.test(value) : true,
    };

    if (validators[name] && !validators[name](value)) {
      error = 'Invalid IP/CIDR format';
    }

    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
    return error === '';
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState(prevState => ({ ...prevState, [name]: value }));
    validateField(name, value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const formFields = { ...formState };

    let isValid = true;
    for (const [name, value] of Object.entries(formFields)) {
      if (!validateField(name, value)) {
        isValid = false;
      }
    }
    if (isValid) {
      onSave({
        id: installation?.id || undefined,
        data_agendamento: formState.dataAgendamento ? formState.dataAgendamento.toDate() : new Date(),
        ipv4LanNetworkAddress: formFields.ipv4LanNetworkAddress !== '' ? formFields.ipv4LanNetworkAddress : undefined,
        ipv4LanBroadcastAddress: formFields.ipv4LanBroadcastAddress !== '' ? formFields.ipv4LanBroadcastAddress : undefined,
        ipv4LanCidr: formFields.ipv4LanCidr !== '' ? Number(formFields.ipv4LanCidr) : undefined,
        ipv4LanRouterInterface: formFields.ipv4LanRouterInterface !== '' ? formFields.ipv4LanRouterInterface : undefined,
        ipv4LanFirstClientAddress: formFields.ipv4LanFirstClientAddress !== '' ? formFields.ipv4LanFirstClientAddress : undefined,
        ipv4LanLastClientAddress: formFields.ipv4LanLastClientAddress !== '' ? formFields.ipv4LanLastClientAddress : undefined,
        ipv4WanNetworkAddress: formFields.ipv4WanNetworkAddress !== '' ? formFields.ipv4WanNetworkAddress : undefined,
        ipv4WanBroadcastAddress: formFields.ipv4WanBroadcastAddress !== '' ? formFields.ipv4WanBroadcastAddress : undefined,
        ipv4WanCidr: formFields.ipv4WanCidr !== '' ? Number(formFields.ipv4WanCidr) : undefined,
        ipv4WanRouterInterface: formFields.ipv4WanRouterInterface !== '' ? formFields.ipv4WanRouterInterface : undefined,
        ipv4WanVivoRouterInterface: formFields.ipv4WanVivoRouterInterface !== '' ? formFields.ipv4WanVivoRouterInterface : undefined,
        ipv6LanNetworkAddress: formFields.ipv6LanNetworkAddress !== '' ? formFields.ipv6LanNetworkAddress : undefined,
        ipv6LanBroadcastAddress: formFields.ipv6LanBroadcastAddress !== '' ? formFields.ipv6LanBroadcastAddress : undefined,
        ipv6LanCidr: formFields.ipv6LanCidr !== '' ? Number(formFields.ipv6LanCidr) : undefined,
        ipv6LanRouterInterface: formFields.ipv6LanRouterInterface !== '' ? formFields.ipv6LanRouterInterface : undefined,
        ipv6LanFirstClientAddress: formFields.ipv6LanFirstClientAddress !== '' ? formFields.ipv6LanFirstClientAddress : undefined,
        ipv6LanLastClientAddress: formFields.ipv6LanLastClientAddress !== '' ? formFields.ipv6LanLastClientAddress : undefined,
        ipv6WanNetworkAddress: formFields.ipv6WanNetworkAddress !== '' ? formFields.ipv6WanNetworkAddress : undefined,
        ipv6WanBroadcastAddress: formFields.ipv6WanBroadcastAddress !== '' ? formFields.ipv6WanBroadcastAddress : undefined,
        ipv6WanCidr: formFields.ipv6WanCidr !== '' ? Number(formFields.ipv6WanCidr) : undefined,
        ipv6WanRouterInterface: formFields.ipv6WanRouterInterface !== '' ? formFields.ipv6WanRouterInterface : undefined,
        ipv6WanVivoRouterInterface: formFields.ipv6WanVivoRouterInterface !== '' ? formFields.ipv6WanVivoRouterInterface : undefined,
        ipLoopbackNetworkAddress: formFields.ipLoopbackNetworkAddress !== '' ? formFields.ipLoopbackNetworkAddress : undefined,
        ipLoopbackCidr: formFields.ipLoopbackCidr !== '' ? Number(formFields.ipLoopbackCidr) : undefined,
        gerencia_edd: formFields.gerenciaEdd,
        cvlan: formFields.cvlan !== '' ? Number(formFields.cvlan) : undefined,
        svlan: formFields.svlan !== '' ? Number(formFields.svlan) : undefined,
        vlan_gerencia: formFields.vlanGerencia !== '' ? Number(formFields.vlanGerencia) : undefined,
        end_cliente: formFields.endCliente,
        os_tbs: formFields.osTbs,
        id_vantive: formFields.idVantive,
        cliente: formFields.cliente,
        equipamentos: formFields.equipamentos,
        designador: formFields.designador,
        velocidade: formFields.velocidade,
        contato: formFields.contato,
        acesso: formFields.acesso,
        armario: formFields.armario,
        vendor: formFields.vendor,
        description: formFields.description,
        categoria: formFields.categoria,
        servico: formFields.servico,
      });
    }
  };

  return (
    <Box component='form' onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DateTimePicker
            label="Data Agendamento"
            value={formState.dataAgendamento}
            onChange={(date) => setFormState(prevState => ({ ...prevState, dataAgendamento: date }))}
            disablePast
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='cliente'
            label="Cliente"
            value={formState.cliente}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='endCliente'
            label="End Cliente"
            value={formState.endCliente}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='contato'
            label="Contato"
            value={formState.contato}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='osTbs'
            label="Ordem TBS"
            value={formState.osTbs}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='designador'
            label="Designador"
            value={formState.designador}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='idVantive'
            label="ID Vantive"
            value={formState.idVantive}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='velocidade'
            label="Velocidade"
            select
            value={formState.velocidade}
            onChange={handleChange}
            fullWidth
          >
            {speedLinks.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='categoria'
            label="Categoria do Serviço"
            select
            value={formState.categoria}
            onChange={handleChange}
            fullWidth
          >
            {categorias.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='servico'
            label="Tipo de Serviço"
            select
            value={formState.servico}
            onChange={handleChange}
            fullWidth
          >
            {serviceTypes.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='armario'
            label="Armário"
            value={formState.armario}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='vendor'
            label="Vendor"
            value={formState.vendor}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='cvlan'
            label="CVLAN"
            type='number'
            inputMode='numeric'
            inputProps={{ pattern: "[0-9]*" }}
            InputLabelProps={{
              shrink: true,
            }}
            value={formState.cvlan}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='svlan'
            label="SVLAN"
            type='number'
            inputMode='numeric'
            inputProps={{ pattern: "[0-9]*" }}
            InputLabelProps={{
              shrink: true,
            }}
            value={formState.svlan}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='vlanGerencia'
            label="VLAN Gerencia"
            type='number'
            inputMode='numeric'
            inputProps={{ pattern: "[0-9]*" }}
            InputLabelProps={{
              shrink: true,
            }}
            value={formState.vlanGerencia}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='acesso'
            label="Acesso / DGO / SW"
            value={formState.acesso}
            onChange={handleChange}
            fullWidth
            maxRows={3}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='gerenciaEdd'
            label="Gerencia EDD"
            value={formState.gerenciaEdd}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='equipamentos'
            label="Equipamentos"
            value={formState.equipamentos}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroups title='IPV4 Lan'>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  name='ipv4LanNetworkAddress'
                  label="Endereço de Rede"
                  value={formState.ipv4LanNetworkAddress}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4LanNetworkAddress}
                  helperText={errors.ipv4LanNetworkAddress}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  name='ipv4LanBroadcastAddress'
                  label="Endereço de Broadcast"
                  value={formState.ipv4LanBroadcastAddress}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4LanBroadcastAddress}
                  helperText={errors.ipv4LanBroadcastAddress}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name='ipv4LanCidr'
                  label="Prefixo de Rede"
                  value={formState.ipv4LanCidr}
                  onChange={handleChange}
                  fullWidth
                  type='number'
                  inputMode='numeric'
                  inputProps={{ pattern: "[0-9]*" }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name='ipv4LanRouterInterface'
                  label="Interface LAN do Router"
                  value={formState.ipv4LanRouterInterface}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4LanRouterInterface}
                  helperText={errors.ipv4LanRouterInterface}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name='ipv4LanFirstClientAddress'
                  label="Primeiro Endereço válido do cliente"
                  value={formState.ipv4LanFirstClientAddress}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4LanFirstClientAddress}
                  helperText={errors.ipv4LanFirstClientAddress}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name='ipv4LanLastClientAddress'
                  label="Último Endereço válido do cliente"
                  value={formState.ipv4LanLastClientAddress}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4LanLastClientAddress}
                  helperText={errors.ipv4LanLastClientAddress}
                />
              </Grid>
            </Grid>
          </FormGroups>
        </Grid>
        <Grid item xs={12}>
          <FormGroups title='IPV6 Lan'>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  name='ipv6LanNetworkAddress'
                  label="Endereço de Rede"
                  value={formState.ipv6LanNetworkAddress}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.ipv6LanNetworkAddress}
                  helperText={errors.ipv6LanNetworkAddress}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  name='ipv6LanBroadcastAddress'
                  label="Endereço de Broadcast"
                  value={formState.ipv6LanBroadcastAddress}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.ipv6LanBroadcastAddress}
                  helperText={errors.ipv6LanBroadcastAddress}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name='ipv6LanCidr'
                  label="Prefixo de Rede"
                  value={formState.ipv6LanCidr}
                  onChange={handleChange}
                  fullWidth
                  type='number'
                  inputMode='numeric'
                  inputProps={{ pattern: "[0-9]*" }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name='ipv6LanRouterInterface'
                  label="Interface LAN do Router"
                  value={formState.ipv6LanRouterInterface}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.ipv6LanRouterInterface}
                  helperText={errors.ipv6LanRouterInterface}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name='ipv6LanFirstClientAddress'
                  label="Primeiro Endereço válido do cliente"
                  value={formState.ipv6LanFirstClientAddress}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.ipv6LanFirstClientAddress}
                  helperText={errors.ipv6LanFirstClientAddress}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name='ipv6LanLastClientAddress'
                  label="Último Endereço válido do cliente"
                  value={formState.ipv6LanLastClientAddress}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.ipv6LanLastClientAddress}
                  helperText={errors.ipv6LanLastClientAddress}
                />
              </Grid>
            </Grid>
          </FormGroups>
        </Grid>
        <Grid item xs={12}>
          <FormGroups title='IPV4 Wan'>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  name='ipv4WanNetworkAddress'
                  label="Endereço de Rede"
                  value={formState.ipv4WanNetworkAddress}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4WanNetworkAddress}
                  helperText={errors.ipv4WanNetworkAddress}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  name='ipv4WanBroadcastAddress'
                  label="Endereço de Broadcast"
                  value={formState.ipv4WanBroadcastAddress}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4WanBroadcastAddress}
                  helperText={errors.ipv4WanBroadcastAddress}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name='ipv4WanCidr'
                  label="Prefixo de Rede"
                  value={formState.ipv4WanCidr}
                  onChange={handleChange}
                  fullWidth
                  type='number'
                  inputMode='numeric'
                  inputProps={{ pattern: "[0-9]*" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='ipv4WanRouterInterface'
                  label="Interface WAN do Router Cliente"
                  value={formState.ipv4WanRouterInterface}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4WanRouterInterface}
                  helperText={errors.ipv4WanRouterInterface}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='ipv4WanVivoRouterInterface'
                  label="Interface WAN Router Vivo"
                  value={formState.ipv4WanVivoRouterInterface}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4WanVivoRouterInterface}
                  helperText={errors.ipv4WanVivoRouterInterface}
                />
              </Grid>
            </Grid>
          </FormGroups>
        </Grid>
        <Grid item xs={12}>
          <FormGroups title='IPV6 Wan'>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  name='ipv6WanNetworkAddress'
                  label="Endereço de Rede"
                  value={formState.ipv6WanNetworkAddress}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.ipv6WanNetworkAddress}
                  helperText={errors.ipv6WanNetworkAddress}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  name='ipv6WanBroadcastAddress'
                  label="Endereço de Broadcast"
                  value={formState.ipv6WanBroadcastAddress}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.ipv6WanBroadcastAddress}
                  helperText={errors.ipv6WanBroadcastAddress}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name='ipv6WanCidr'
                  label="Prefixo de Rede"
                  value={formState.ipv6WanCidr}
                  onChange={handleChange}
                  fullWidth
                  type='number'
                  inputMode='numeric'
                  inputProps={{ pattern: "[0-9]*" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='ipv6WanRouterInterface'
                  label="Interface WAN do Router Cliente"
                  value={formState.ipv6WanRouterInterface}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.ipv6WanRouterInterface}
                  helperText={errors.ipv6WanRouterInterface}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='ipv6WanVivoRouterInterface'
                  label="Interface WAN Router Vivo"
                  value={formState.ipv6WanVivoRouterInterface}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.ipv6WanVivoRouterInterface}
                  helperText={errors.ipv6WanVivoRouterInterface}
                />
              </Grid>
            </Grid>
          </FormGroups>
        </Grid>
        <Grid item xs={12}>
          <FormGroups title='IP LOOPBAck'>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  name='ipLoopbackNetworkAddress'
                  label="Endereço de Rede"
                  value={formState.ipLoopbackNetworkAddress}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipLoopbackNetworkAddress}
                  helperText={errors.ipLoopbackNetworkAddress}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name='ipLoopbackCidr'
                  label="Prefixo Loopback"
                  value={formState.ipLoopbackCidr}
                  onChange={handleChange}
                  fullWidth
                  type='number'
                  inputMode='numeric'
                  inputProps={{ pattern: "[0-9]*" }}
                />
              </Grid>
            </Grid>
          </FormGroups>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='description'
            label="Descrição"
            value={formState.description}
            onChange={handleChange}
            fullWidth
            multiline
            maxRows={3}
            inputProps={{ maxLength: 155 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button className='button' type="submit" variant="contained">
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Vivo2CircuitForm;