import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';

type Props = {
  maxSecondsTime: number;
  descriptions: string[];
  showTimer?: boolean;
};

const colors: Array<'primary' | 'warning' | 'error'> = ['primary', 'warning', 'error'];

/**
 * Create a Circular Progress with a percentage label
 * @param {number} maxSecondsTime max time (in seconds) to complete the percentage at 100%  
 * @param {string[]} descriptions Array of text to show in loading screen during the maxSecondsTime 
 * @param {boolean} showTimer Boolean to show a timer of the loading
 * @returns 
 */
export default function CircularProgressIndeterminateWithLabel({ maxSecondsTime, descriptions, showTimer }: Props) {
  // const [progress, setProgress] = useState(0);
  const [time, setTime] = useState(0);
  const [progressText, setProgressText] = useState(descriptions[0]);
  const [progressColor, setProgressColor] = useState(colors[0]);

  // Minutes calculation
  const minutes = Math.floor((time % 360000) / 6000);

  // Seconds calculation
  const seconds = Math.floor((time % 6000) / 100);

  // Milliseconds calculation
  // const milliseconds = time % 100;

  const interval = (maxSecondsTime / descriptions.length) * 1000;
  // const ratio = (100 / maxSecondsTime) * (interval / 1000);

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    intervalId = setInterval(() => setTime(time + 1), 10);
    return () => clearInterval(intervalId);
  }, [time]);

  useEffect(() => {
    const timer = setInterval(() => {
      // setProgress((prevProgress) => (prevProgress + ratio >= 100 ? 99 : prevProgress + ratio));
      setProgressText((prevText) => (descriptions[descriptions.indexOf(prevText) + 1] ?? descriptions[descriptions.length - 1]));
      setProgressColor((prevColor) => (colors[colors.indexOf(prevColor) + 1] ?? colors[colors.length - 1]));
    }, interval);
    return () => {
      clearInterval(timer);
    };
  }, [descriptions, interval]);

  return (
    <Box >
      <CircularProgress size={100} color={progressColor} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 200,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        {showTimer && (
          <Typography
            variant="h3"
            component="div"
            fontSize={20}
          >
            {`Tempo de execução, em média, de 5 minutos: ${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`}
          </Typography>
        )}
        <Typography
          variant="h3"
          component="div"
          fontSize={20}
        >
          {`${progressText}`}
        </Typography>
      </Box>
    </Box>
  );
}