import React from 'react';
import TextField, { TextFieldVariants } from '@mui/material/TextField';

interface Props {
  data: Record<string, any>;
  variant?: TextFieldVariants;
}

/**
 * Render recursively all the 'key' and 'value' pair of an object data to display in a Material-UI TextField component
 * @param data The object which the attributes will be rendered
 * @param labelMap If provided, it will customize the label display of that attribute and will only render the attributes mapped
 * @param variant Will use the variant of TextField provided, 'outlined' is the default variant
 * @returns 
 */
const RecursiveTextField: React.FC<Props> = ({ data, variant }) => {

  const renderFields = (object: Record<string, any>, parentKey?: string): React.ReactNode[] => {
    return Object.entries(object).map(([key, value]) => {
      const fieldName = parentKey ? `${parentKey}.${key}` : key;

      if (typeof value === 'object' && value !== null) {
        // Recursively render nested objects
        return renderFields(value, fieldName);
      } else {
        // Render TextField for leaf values
        return (
          <TextField
            key={fieldName}
            label={fieldName}
            value={value}
            variant={variant ?? "outlined"}
            margin="normal"
            InputProps={{
              readOnly: true
            }}
          />
        );
      }
    });
  };

  return <>{renderFields(data)}</>;
};

export default RecursiveTextField;