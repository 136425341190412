import { useEffect } from "react";
import { useUserContext } from "../contexts/UserContext";
import axios, { AxiosError } from "axios";
import { useKeycloak } from '@react-keycloak/web';
import { ApiResponse } from "../interfaces/ApiResponse";
import { IApiError } from "../interfaces/IApiError";

const useAuthentication = () => {
  const { handleAuth } = useUserContext();
  const { keycloak } = useKeycloak();
  const isAuthenticated = keycloak.authenticated;

  useEffect(() => {
    if (isAuthenticated) {
      keycloak.loadUserInfo().then((user) => {
        handleAuth(true, user);
      });
    }
  }, [isAuthenticated, keycloak, handleAuth]);

  // Logout
  const logout = async (data?: any) => {
    keycloak.logout();
  };

  // Function to refresh the access token
  const refreshAccessToken = async () => {
    try {
      const updateTokenResponse = await keycloak.updateToken(5); // Refresh token with 5 minutes buffer
      console.log(updateTokenResponse);
      const newAccessToken = keycloak.token;
      return newAccessToken;
    } catch (error) {
      console.log(error);
      logout();
    }
  };

  // Axios interceptor to automatically refresh token on 401 response
  axios.interceptors.response.use(
    (response) => response,
    async (error: AxiosError<ApiResponse<any>>) => {
      if (error.response?.status === 401) {
        const responseBody = error.response.data.error;
        if (responseBody && (responseBody as IApiError).cause && (responseBody as IApiError).cause![0].detail === "Token expired.") {
          const newAccessToken = await refreshAccessToken();
          if (newAccessToken) {
            // Retry the original request with the new access token
            const originalRequest = error.config;
            originalRequest!.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios(originalRequest!);
          }
        }
      }
      return Promise.reject(error);
    }
  );

  // Handle refresh errors
  useEffect(() => {
    const handleAuthRefreshError = () => {
      console.error('Authentication token refresh error');
      // keycloak.logout({ redirectUri: window.location.href });
      keycloak.login();
    };

    keycloak.onAuthRefreshError = handleAuthRefreshError;
  }, [keycloak]);

  return { logout };
};

export default useAuthentication;
