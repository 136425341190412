import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import { IUserContext } from "../interfaces/UserContext";
import { useKeycloak } from '@react-keycloak/web';
import { Roles } from "../utils/EnumUtils";

export const UserContext = createContext<IUserContext | undefined>(undefined);

export const UserProvider = ({
  children
}: { children: ReactNode; }) => {

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null); //TODO Retrieve initialState from LocalStorage
  const [user, setUser] = useState<any | null>(null); //TODO Retrieve initialState from LocalStorage
  const { keycloak } = useKeycloak();

  const handleAuth = useCallback((auth: boolean, user: any) => {
    setIsAuthenticated(auth);
    setUser(user);
  }, []);

  const userHasRole = (roles: string[]) => {
    return roles.some((role) => keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role));
  };

  const userHasAdminRole = () => {
    return keycloak.hasRealmRole(Roles.admin) || keycloak.hasResourceRole(Roles.admin);
  };

  return (
    <UserContext.Provider value={{ isAuthenticated, user, handleAuth, userHasRole, userHasAdminRole }}>{children}</UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};