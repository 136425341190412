import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InternetFacility, Value } from '../interfaces/InternetFacility';
import { Alert, Backdrop, Box, Button, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import DynamicForm from './DynamicForm';
import DynamicView from './DynamicView';
import { HpsdConfigBodyRequest } from '../interfaces/HpsdConfigBodyRequest';
import { hpsdConfigCallbackRequest, hpsdConfigRequest } from '../services/apiSys';
import handleError from '../utils/handleError';
import { InputCustom } from '../interfaces/InputCustom';
import CircularProgressIndeterminateWithLabel from './CircularProgressIndeterminateWithLabel';
import useHpsd from '../hooks/useHpsd';

type Props = {
  dataFacility: InternetFacility;
};

// type GenericField = {
//   name: string;
//   value: string;
// };

const erbAcessoLabelDisplay: Record<string, InputCustom> = {
  'SIGLA_ERB': { label: 'ERB', mapRequestName: 'ERB', required: true },
};

const motives = [
  'Porta Ocupada',
  'Rede Saturada',
  'Distância',
  'Porta com defeito',
  'Acerto de Cadastro',
  'Alívio de Rede',
];

const ManobraErbDisplay = ({ dataFacility }: Props) => {

  const [dataFacilityUpdated, setDataFacilityUpdated] = useState<InternetFacility>(dataFacility);
  const [motive, setMotive] = useState<string>('');
  const [resultId, setResultId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');
  const notificationRef = useRef<HTMLDivElement>(null);

  const { geralCharacteristic, findServiceCharacteristicValue, findServiceCharacteristicCallbackValue } = useHpsd(dataFacility);

  /**
   * Get the attributes of Geral Characteristics returned from /tmf-638/facilities
   */
  // const geralCharacteristic = getCharacteristic("GERAL");

  /**
   * Get the attributes of ERB Acesso  Characteristics returned from /tmf-638/facilities
   * Get it's index to update the data in the dataFacilityUpdated
   */
  const { erbAcessoIndexOf, erbAcessoCharacteristic }: { erbAcessoIndexOf: number; erbAcessoCharacteristic: Value; } = useMemo(() => {
    let index = dataFacility.serviceCharacteristic.findIndex((service) => service.name === "ERB.ACESSO");
    return { erbAcessoIndexOf: index, erbAcessoCharacteristic: dataFacility.serviceCharacteristic[index].value };
  }, [dataFacility]); // Invoking the function immediately to get the initial values


  /**
   * Scroll to the notification
   */
  useEffect(() => {
    if (notificationRef.current) {
      notificationRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, [error, success]);

  //Method to ping the Hpsd Config Callback
  const pingConfigStatus = useCallback(async (resultId: string, intervalId?: NodeJS.Timer) => {
    try {
      const resp = await hpsdConfigCallbackRequest(resultId);
      if (resp.data && Object.keys(resp.data).length > 0) {
        // If the config is finished, clear the interval
        clearInterval(intervalId);
        if (resp.data.event.serviceOrder.serviceOrderItem) {
          const service = resp.data.event.serviceOrder.serviceOrderItem[0].service;

          if (service.category === 'ManobraRecursos.MNERB.05') {
            // const statusManobra = resp.data.event.serviceOrder.state;
            const erbReservada = findServiceCharacteristicCallbackValue(resp.data.event.serviceOrder.serviceOrderItem[0].service.serviceCharacteristic, "ErbReservada");
            if (erbReservada) {
              console.log('Manobra Finalizada com sucesso.');
              setSuccess('Manobra finalizada com sucesso.');
            } else {
              setError(resp.data.event.serviceOrder.description ?? 'Manobra finalizada com erro.');
            }
            setLoading(false);
          }
        } else {
          setError('Timeout - não recebeu resposta do callback HPSD.');
          setLoading(false);
        }

        return resp.data;
      }
    } catch (error) {
      handleError({ error, setError });
      clearInterval(intervalId);
      setLoading(false);
      // setResultId('');
    }
  }, [findServiceCharacteristicCallbackValue]);

  /**
   * Request callback response from the config
   */
  useEffect(() => {
    if (resultId) {

      // Set up a periodic ping every 5 seconds to check if the Config Task is already finished and get the result
      const intervalId = setInterval(async () => {
        // Check task status initially
        pingConfigStatus(resultId, intervalId);
      }, 5000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [resultId, pingConfigStatus]);

  /**
   * Updated the dataFacilityUpdated with the changes made by the user
   * @param value 
   * @param key 
   */
  const handleInputChangeForm = (value: string | number, key: string) => {
    const updatedServiceCharacteristic = [...dataFacilityUpdated.serviceCharacteristic]; // Create a copy of the array
    updatedServiceCharacteristic[erbAcessoIndexOf] = {
      ...updatedServiceCharacteristic[erbAcessoIndexOf],
      value: {
        ...updatedServiceCharacteristic[erbAcessoIndexOf].value,
        [key]: value
      }
    };
    setDataFacilityUpdated({
      ...dataFacility,
      serviceCharacteristic: updatedServiceCharacteristic
    });
  };

  /**
   * Get ErbCobertura object by erbSigla
   * @param erbSigla 
   * @returns Erb_Cobertura data
   */
  const findErbCoberturaByErbSigla = (erbSigla: string) => {
    const coberturas = findServiceCharacteristicValue(dataFacility.serviceCharacteristic, "ERB.ACESSO")?.ERBS_COBERTURA;
    console.log(coberturas);
    return coberturas?.find(c => c.sigla === erbSigla);
  };

  /** Execute Manobra requesting category MNERB.05 in HPSD */
  const handleSubmitManobra = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    setResultId('');
    setLoading(true);
    try {
      const newErbValue = findServiceCharacteristicValue(dataFacilityUpdated.serviceCharacteristic, "ERB.ACESSO")?.SIGLA_ERB;
      if (newErbValue) {
        const cobertura = findErbCoberturaByErbSigla(newErbValue);

        if (!cobertura) {
          throw new Error('Digite uma sigla válida dentro da mancha de cobertura');
        }

        const body: HpsdConfigBodyRequest = {
          service: {
            id: dataFacility.id,
            category: "ManobraRecursos.MNERB.05",
            // serviceOrderItem: "serviceOrderItem_ID1",
            serviceSpecification: "Internet"
          },
          fields: [
            { name: "erb", value: [cobertura] },
            { name: "NaturezaOrdem", value: "Manobra" },
            { name: 'REALLOCATION_REASON', value: motive },
            { name: 'REALLOCATION_REASON_TEMP', value: motive },
          ]
        };
        const resp = await hpsdConfigRequest(body);
        setResultId(resp.data?.id_result);
      }
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  return (
    <Box width={'100%'}>
      <Stack sx={{ width: '100%', marginTop: 1 }} spacing={2}>
        <div ref={notificationRef}>
          {error && (
            <Alert severity="error" variant='filled' onClose={() => setError('')}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" variant='filled' onClose={() => setSuccess('')}>
              {success}
            </Alert>
          )}
        </div>
      </Stack>
      {geralCharacteristic && erbAcessoCharacteristic && (
        <Box
          width="100%"
          marginTop={1}
          component="form"
          onSubmit={handleSubmitManobra}
        >
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Card>
                <CardContent sx={{
                  '& .MuiTextField-root': { m: 1 },
                  display: 'flex',
                  justifyContent: 'space-around'
                }}>
                  <TextField
                    label="Tipo Acesso"
                    value={geralCharacteristic.TIPO_ACESSO}
                    variant='standard'
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                  />
                  <TextField
                    label="Rede"
                    value={geralCharacteristic.PLACE_RESERVED?.network_owner}
                    variant='standard'
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                  />
                  <TextField
                    label="Serviço"
                    value={geralCharacteristic.TIPO_SERVICO}
                    variant='standard'
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                  />
                  <TextField
                    label="Razão Social"
                    value={geralCharacteristic.RAZAO_SOCIAL}
                    variant='standard'
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} display={'grid'}>
              <Card className='card'>
                <CardHeader
                  title="Posição Atual"
                  titleTypographyProps={{ textAlign: "center" }}
                />
                <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                  <DynamicView
                    data={erbAcessoCharacteristic}
                    labelMap={erbAcessoLabelDisplay}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} display={'grid'}>
              <Card className='card'>
                <CardHeader
                  title="Posição Desejada"
                  titleTypographyProps={{ textAlign: "center" }}
                />
                <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                  <DynamicForm
                    data={dataFacilityUpdated.serviceCharacteristic[erbAcessoIndexOf].value}
                    labelMap={erbAcessoLabelDisplay}
                    handleInputChange={handleInputChangeForm}
                  />
                  <FormControl fullWidth margin='normal'>
                    <InputLabel>Motivo</InputLabel>
                    <Select
                      value={motive}
                      label="Motivo"
                      required
                      onChange={(e) => setMotive(e.target.value as string)}
                    >
                      {motives.map((motive) => (
                        <MenuItem
                          key={motive}
                          value={motive}
                        >
                          {motive}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                className='button'
                type='submit'
                variant="contained"
                disabled={loading} // || compare(dataFacility, dataFacilityUpdated)
              >
                {loading ? `Manobrando...` : `Realizar Manobra`}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
          open={loading}
        >
          {/* <CircularProgress color="inherit" /> */}
          {/* <CircularWithValueLabel maxSecondsTime={180} interval={5000} /> */}
          <CircularProgressIndeterminateWithLabel maxSecondsTime={600} descriptions={
            ['Processo em andamento...', 'Processo demorando mais que o normal...', 'Provável falha na consulta por timeout...']
          }
          />
        </Backdrop>
      )}
    </Box>
  );
};

export default ManobraErbDisplay;