import styles from './IpLight.module.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//External
import { OutlinedInput, Card, CardContent, Typography, IconButton, Skeleton, Button, MenuItem, Select, Divider } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
//Interfaces
import { IPLightList } from '../../interfaces/IPLightList';
//Services
import { ipLightListFilterRequest, ipLightListRequest } from '../../services/apiWeb';
//Components
import StatusIcon from '../../components/StatusIcon';
import handleError from '../../utils/handleError';

enum Filter {
  client_id = 'Client ID' as any,
  protocol_id = 'Protocolo' as any,
  serial_number = 'Serial' as any,
}

const IpLight = () => {

  const [filteredData, setFilteredData] = useState<IPLightList | null>(null);
  const [selectedQueryOption, setSelectedQueryOption] = useState<Filter>(Filter.client_id);
  const [queryValue, setQueryValue] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  //Get initial list of 10
  const getIpLightList = async () => {
    try {
      const res = await ipLightListRequest();
      setFilteredData(res);
    } catch (error) {
      handleError({ error, setError });
    }
  };

  //Execute the API Request to return the last 10 Ip Light certs
  useEffect(() => {
    getIpLightList();
  }, []);

  //Filter the list by client_id value of input text
  // const filterList = (value: string) => {
  //   if (ipLightList) {
  //     const filtered = ipLightList.data.filter(data =>
  //       data.client_id.includes(value)
  //     );
  //     setFilteredData({ ...ipLightList, data: filtered });
  //   }
  // };

  const handleQueryApiRequest = async () => {
    try {
      setLoading(true);
      setFilteredData(null);
      setError("");

      const res = await ipLightListFilterRequest(Filter[selectedQueryOption], queryValue);
      setFilteredData(res);
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.query_div}>
        <Select
          className={styles.select}
          autoWidth
          value={selectedQueryOption}
          onChange={(e) => setSelectedQueryOption(e.target.value as Filter)}
        >
          <MenuItem value={Filter.client_id}>{Filter.client_id}</MenuItem>
          <MenuItem value={Filter.protocol_id}>{Filter.protocol_id}</MenuItem>
          <MenuItem value={Filter.serial_number}>{Filter.serial_number}</MenuItem>
        </Select>
        <OutlinedInput
          className={styles.query_input}
          placeholder={`Busque por ${selectedQueryOption}`}
          value={queryValue}
          onChange={(e) => setQueryValue(e.target.value)}
        />
        <Button
          className='button'
          variant="contained"
          color="primary"
          onClick={handleQueryApiRequest}
          disabled={loading}
        >
          Buscar
        </Button>
      </div>
      <Divider flexItem sx={{ margin: '2em 0' }} />
      {/* <OutlinedInput
        className={styles.search_input}
        placeholder='Filtre pelo Client ID'
        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
        onChange={(e) => filterList(e.currentTarget.value)}
      /> */}
      {filteredData ? (
        filteredData.data.map((data) => (
          <Card key={data.id} className={styles.card}>
            <CardContent className={styles.card_content}>
              <div className={styles.info}>
                <Typography sx={{ fontWeight: '600' }}>
                  Client ID: {data.client_id}
                </Typography>
                <Typography >
                  <span>Modelo:</span> {data.model}
                </Typography>
                <Typography >
                  <span>Protocolo:</span> {data.protocol_id}
                </Typography>
                <Typography >
                  <span>Firmware:</span> {data.firmware}
                </Typography>
                <StatusIcon status={data.approved} text={["Aprovado", "Reprovado"]} />
                <Typography >
                  {data.created_at}
                </Typography>
              </div>
            </CardContent>
            <div className={styles.details_button}>
              <IconButton component={Link} to={data.id}>
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </Card>
        ))
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <Skeleton variant="rectangular" className={styles.card_skeleton} />
      )}
    </div >
  );
};

export default IpLight;