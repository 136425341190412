import { Autocomplete, Box, Button, Skeleton, TextField } from '@mui/material';
import React, { FormEvent, useEffect, useState } from 'react';
import handleError from '../../utils/handleError';
import { trackingHistoricVivo2Request } from '../../services/apiSys';
import { IatHistoricConfig } from '../../interfaces/IatHistoricConfig';
import Vivo2HistoricActivities from '../../components/Vivo2HistoricActivities';

type Props = {};

const Vivo2Historic = (props: Props) => {
  const [searchId, setSearchId] = useState<string>('');
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [historic, setHistoric] = useState<IatHistoricConfig[] | undefined>(undefined);

  useEffect(() => {
    // Load search history from localStorage when the component mounts
    const storedHistory = localStorage.getItem('internetIdSearchHistory');
    if (storedHistory) {
      setSearchHistory(JSON.parse(storedHistory));
    }
  }, []);

  const saveSearchHistory = (history: string[]) => {
    // Save search history to localStorage
    localStorage.setItem('internetIdSearchHistory', JSON.stringify(history));
  };

  const handleSearchHistoric = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      setError("");
      setHistoric(undefined);
      if (searchId.trim() !== '' && !searchHistory.includes(searchId)) {
        // Add the search input to the history
        const updatedHistory = [searchId, ...searchHistory.slice(0, 9)];
        setSearchHistory(updatedHistory);
        // Save the updated history to localStorage
        saveSearchHistory(updatedHistory);
      }
      const resp = await trackingHistoricVivo2Request([{ key: 'designador', value: searchId }]);
      setHistoric(resp.data ?? []);
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='main'>
      <Box
        component="form"
        onSubmit={handleSearchHistoric}
        display={'flex'}
        width={'100%'}
      >
        <Autocomplete
          id='input-internet-id'
          freeSolo
          options={searchHistory}
          fullWidth
          value={searchId}
          onInputChange={(e, value) => setSearchId(value)}
          renderInput={(params) => <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              className: 'query-input',
            }}
            label="Designador de acesso"
            required
          />
          }
        />
        <Button
          className='button'
          sx={{ marginLeft: '1em' }}
          variant="contained"
          color="primary"
          disabled={loading}
          type='submit'
        >
          {loading ? 'Buscando...' : 'Buscar'}
        </Button>
      </Box>
      {historic && (
        <Vivo2HistoricActivities data={historic} />
      )}
      {error && <p className='error'>{error}</p>}
      {loading &&
        <Skeleton variant='rounded' width='100%' height={500} />
      }
    </div>
  );
};

export default Vivo2Historic;
