import { Box, Checkbox, FormControlLabel, Skeleton } from '@mui/material';
import styles from './ConsultVantive.module.css';
import { useEffect, useState } from 'react';
import IatLegacyDisplay from '../../components/IatLegacyDisplay';
import { IatInfoData } from '../../interfaces/IatInfoData';
import { lpRouterSearchRequest } from '../../services/apiSys';
import IatSearchIdVantive from '../../components/IatSearchIdVantive';

const ConsultVantive = () => {
  const [equipInfo, setEquipInfo] = useState<IatInfoData>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [checkStar, setCheckStar] = useState<boolean>(false);
  const [searchStar, setSearchStar] = useState<boolean>();

  useEffect(() => {
    setSearchStar(checkStar);
    setEquipInfo(undefined);
  }, [checkStar]);

  return (
    <div className='main'>
      <h3 className={styles.title}>Consultar ID Vantive</h3>
      <Box
        display={'flex'}
        width={'100%'}
      >
        <FormControlLabel
          label='Consultar Star'
          labelPlacement='start'
          sx={{ margin: '0.5em', textAlign: 'center' }}
          control={
            <Checkbox
              checked={checkStar}
              onChange={(e) => { setCheckStar(e.target.checked); }}
            />
          }
        />
      </Box>
      <IatSearchIdVantive
        apiRequest={lpRouterSearchRequest}
        setEquipInfo={setEquipInfo}
        loading={{ isLoading: loading, setLoading }}
        setError={setError}
        searchStar={searchStar}
      />
      <div className={styles.info_div}>
        {equipInfo && (
          <IatLegacyDisplay info={equipInfo} isStar={searchStar} />
        )}
        {loading && <Skeleton variant="rounded" width='100%' height={500} />}
        {error && <p className='error'>{error}</p>}
      </div>
    </div>
  );
};

export default ConsultVantive;