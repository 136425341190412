import styles from './IatLegacyDisplay.module.css';
import { Box, Button, Grid, Card, Typography, CardContent, MenuItem, TextField, Divider, Backdrop, Alert, Stack, IconButton } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { FormEvent, Fragment, useState, useEffect, useRef, ChangeEvent } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyAllIcon from '@mui/icons-material/CopyAll';
//Interfaces
import { IatInfoData } from '../interfaces/IatInfoData';
import { IatConfigResultDetail, InfoTest } from '../interfaces/IatConfigResultDetail';
import { InputCustom } from '../interfaces/InputCustom';
import { IatTracking } from '../interfaces/IatTracking';
//Utils
import handleError from '../utils/handleError';
//Components
import IatResultDetail from './IatResultDetail';
import IatHistoricActivities from './IatHistoricActivities';
import { configurationCheckRequest, configurationResultCheckRequest, duplicityCheckRequest, duplicityResultCheckRequest, lpRouterConfigRequest, lpRouterResultConfigRequest, oltCheckRequest, oltResultCheckRequest, programmingCheckRequest, programmingResultCheckRequest, swtCheckRequest, swtResultCheckRequest, trackingIatListRequest } from '../services/apiSys';
import IatFinishTaskSnackbar from './IatFinishTaskSnackbar';
import TaskTrackingDetailCard from './TaskTrackingDetailCard';
import { handleInputChange } from '../utils/FormsUtil';
import DynamicForm from './DynamicForm';
import CircularProgressIndeterminateWithLabel from './CircularProgressIndeterminateWithLabel';
import { IatTaskConfig } from '../interfaces/IatTaskConfig';
import { IatTaskCheck } from '../interfaces/IatTaskCheck';
import DynamicView from './DynamicView';

type Props = {
  info: IatInfoData;
  config?: IatTaskConfig;
  check?: IatTaskCheck;
  isStar?: boolean;
};

enum MainButton {
  Ordem = 'Informações de Ordem',
  Config = 'Configuração',
  Check = 'Validação',
  Historic = 'Histórico Atividades',
}

enum Tech {
  Gpon = 'GPON',
  Praps = 'PRAPS',
  Switch = 'SWT',
}

enum TipoRede {
  LEGADO = 1,
  FUSION = 2,
}

enum ActivationType {
  ROUTER = "LP+ROUTER",
}

enum DiagnosticType {
  SWT = "SWT",
  OLT = "OLT",
  Conf = "Configuração",
  Prog = "Programação",
  Duplicity = "Duplicidade",
}

type ButtonInfo = {
  id: number;
  type: MainButton;
  label: string;
  disabled?: boolean;
};

type RowData = {
  label: string;
  value: string;
};

type RowsType = {
  rows: RowData[];
};

//eslint-disable-next-line
const INTERFACE_PATTERN = '(^((\\d\/)+\\d\\.\\d{7,8})$)';
const HOSTNAME_PATTERN = '^(([A-Za-z0-9]{1,4}[\\-])*([A-Za-z0-9]{1,4}[\\-]){3}([A-Za-z0-9]{1,4}))';
const IP_PATTERN = '^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$';

const buttons: ButtonInfo[] = [
  { id: 1, type: MainButton.Ordem, label: MainButton.Ordem },
  { id: 2, type: MainButton.Config, label: MainButton.Config },
  { id: 10, type: MainButton.Check, label: MainButton.Check },
  { id: 20, type: MainButton.Historic, label: MainButton.Historic },
];

const customInputMainForm: Record<string, InputCustom> = {
  pePrincipal: { label: 'PE Principal', required: true, placeholder: 'Hostname PE', regexPattern: HOSTNAME_PATTERN },
  interfacePePrincipal: { label: 'Interface PE (Posição + VLANS)', required: true, placeholder: "Ex: 3/0/1.1840299", regexPattern: INTERFACE_PATTERN },
  ipWanPe: { label: 'LP WAN PE', required: true, regexPattern: IP_PATTERN },
  mascaraWanPe: { label: 'Mascara WAN', required: true },
  velocidadeLink: { label: 'Velocidade Link', required: true },
  idUnidadeVelocidadeLink: { label: 'Unidade Velocidade Link', options: [{ value: 1, label: 'kbps' }, { value: 2, label: 'mbps' }, { value: 3, label: 'gbps' }], required: true },
  peBackup: { label: 'PE Backup', placeholder: 'Hostname PE Backup', regexPattern: HOSTNAME_PATTERN },
  networkLanCPE: { label: 'Rede Lan CPE' },
  ipLanCpe: { label: 'Ip Lan CPE', required: true },
  mascaraLanCpe: { label: 'Máscara Lan', required: true },
  idRouterCliente: { label: 'Router Cliente', options: [{ value: 1, label: 'Sim' }, { value: 2, label: 'Não' }], required: true },
  ipLoopback: { label: 'Ip Loopback' },
  idSmart: { label: 'ID Smart' },
};

const customLabelsGpon: Record<string, InputCustom> = {
  olt: { label: 'OLT', required: true },
  oltCartao: { label: 'Cartão', required: true },
  oltPorta: { label: 'Porta', required: true },
  oltIdCliente: { label: 'ID Cliente / ID GPON', required: true },
  idOnt: { label: 'ID ONT / SLID', required: true },
  idModeloOlt: { label: 'ID Modelo Olt', readonly: true },
  idModeloOltBackup: { label: 'ID Modelo Olt Backup', readonly: true },
  oltCaboAlimentador: { label: 'Cabo Alimentador' },
  oltFibraAlimentador: { label: 'Fibra Alimentador' },
  oltFibraDistribuidor: { label: 'Fibra Distribuidor' },
};

const customLabelsPraps: Record<string, InputCustom> = {
  dslam: { label: 'DSLAM', required: true, regexPattern: IP_PATTERN },
  dslamSlot: { label: 'Slot', required: true },
  dslamPortaMaster: { label: 'Porta Master', required: true },
  dslamPorta2: { label: 'Porta 2' },
  dslamPorta3: { label: 'Porta 3' },
  dslamPorta4: { label: 'Porta 4' },
  ipModem: { label: 'IP Modem', required: true, regexPattern: IP_PATTERN },
  ipModemRede: { label: 'IP Modem Rede', readonly: true },
  tipoModem: { label: 'Tipo Modem', readonly: true },
};

const customLabelsVpn: Record<string, InputCustom> = {
  classVoip: { label: 'Class Voip' },
  classVideo: { label: 'Class Video' },
  classPlatino: { label: 'Class Platino' },
  classOuro: { label: 'Class Ouro' },
  classPrata: { label: 'Class Prata' },
  classMultimidiaVpnIp: { label: 'Class Multimídia VPN IP' },
  classSuporte: { label: 'Class Suporte' },
};

const customLabelsCheckParams: Record<string, InputCustom> = {
  idSeguranca: { label: 'ID Segurança' },
  ipLanCpe2: { label: 'IP LAN CPE 2' },
  interfaceLanCpe2: { label: 'Interface LAN CPE 2' },
  mascaraLanCpe2: { label: 'Mascara LAN CPE 2' },
  interfaceSwcPrincipal: { label: 'Interface SWC Principal' },
  interfaceSwcBackup: { label: 'Interface SWC Backup' },
  ipLoopback2: { label: 'IP Loopback2' },
  ipWanNavegavel: { label: 'IP WAN Navegavel' },
  ipWanPe2: { label: 'IP WAN PE 2' },
  linkRedundante: { label: 'Link Redundante' },
  produtoMss: { label: 'Produto MSS' },
  sdwan: { label: 'ID Segurança' },
  vrfCadastro: { label: 'VRF Cadastro' },
};

const Rows = ({ rows }: RowsType) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      '& hr': {
        mx: 0.5,
      },
      width: '100%',
      justifyContent: 'flex-start',
    }}>
    {rows.map((data, index) => (
      <Fragment key={index}>
        <div style={{ width: '50%' }}>
          <div style={{ color: '#8083A3' }}>{data.label}</div>
          <span>{data.value ?? '-'}</span>
        </div>
        {index < rows.length - 1 && <Divider orientation='vertical' flexItem />}
      </Fragment>
    ))}
  </Box>
);

const SummaryInfo = ({ summaryTestInfo }: { summaryTestInfo: Array<InfoTest[]>; }) => (
  <Card className={styles.card} sx={{ background: "rgba(128, 131, 163, 0.05)" }}>
    <CardContent>
      <Box
        sx={{
          '& hr': {
            my: 0.5,
          },
          '& span': {
            fontWeight: 'bold'
          }
        }}
      >
        {summaryTestInfo.map((data, index) => (
          <div key={index} className={styles.summary_info_row}>
            <Rows rows={data.map((column: any) => column)} />
            {index < summaryTestInfo.length - 1 && <Divider />}
          </div>
        ))}
      </Box>
    </CardContent>
  </Card>
);

/**
 * Display component for IAT legacy products, display a form with the IAT Data info
 * and tab options to make a Configuration, Validation and check Historic of the ID Vantive. 
 * @param {IatInfoData} info Data returned from the IAT Legacy API searched by ID Vantive.
 * @param {IatTaskConfig} config APIs needed to make the Configuration of a product using IAT legacy APIs.
 * @param {IatTaskCheck} check APIs needed to make the Check of a product using IAT legacy APIs.
 * @param {boolean} isStar Boolean informing if the IatInfoData comes from the database Star or IAT.
 * @returns 
 */
const IatLegacyDisplay = ({ info, config, check, isStar = true }: Props) => {
  const [configTaskApi, setConfigTaskApi] = useState<IatTaskConfig | undefined>(config);
  const [checkTask, setCheckTask] = useState<IatTaskCheck | undefined>(check);
  const [activeButtonId, setActiveButtonId] = useState<MainButton>(MainButton.Ordem);
  const [technologySelected, setTechnologySelected] = useState<string>(info.tech.name ?? '');
  const [configResponse, setConfigResponse] = useState<IatConfigResultDetail>();
  const [checkResponse, setCheckResponse] = useState<IatConfigResultDetail>();
  const [lpData, setLpData] = useState<IatInfoData>(info);
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");
  const [loading, setLoading] = useState(false);
  const [summaryInfoTest, setSummaryInfoTest] = useState<Array<InfoTest[]> | undefined>(undefined);
  const [configTaskId, setConfigTaskId] = useState<string>();
  const [configProduct, setConfigProduct] = useState<string>();
  const [checkTaskId, setCheckTaskId] = useState<string>();
  const [tracking, setTracking] = useState<IatTracking | undefined>(undefined);
  const [openFinishTaskSnackbar, setOpenFinishTaskSnackbar] = useState(false);
  // const submitButtonRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [activationSelected, setActivionSelected] = useState<string>('');
  const [diagnosticSelected, setDiagnosticSelected] = useState<string>('');

  const tipoRede = TipoRede[lpData.tipoRede];

  //Validate the fields at initial render
  // useEffect(() => {
  //   // Trigger the submit button click
  //   if (submitButtonRef.current) {
  //     submitButtonRef.current.click();
  //   }
  // }, []);

  //Ping for config task status
  useEffect(() => {
    if (configTaskId) {
      const pingConfigTaskStatus = async () => {
        try {
          if (configTaskApi) {
            // Ping the API to check if the task is finished
            const response = await configTaskApi.resultConfigApi(configTaskId!);

            if (response.data && Object.keys(response.data).length > 0) {
              // If the task is finished, clear the interval
              clearInterval(intervalId);
              // Handle the results data
              setConfigResponse(response.data);
              //Separate the infoTeste array in rows with n columns
              buildSummaryInfoTest(response.data.infoTest, 2);
              //Enable button and task bar for certification and finalization of task
              setOpenFinishTaskSnackbar(true);

              setLoading(false);
            }
          }
        } catch (error) {
          handleError({ error, setError });
          clearInterval(intervalId);
          setLoading(false);
        }
      };

      // Set up a periodic ping every 5 seconds to check if the Config Task is already finished and get the result
      const intervalId = setInterval(async () => {
        // Check task status initially
        pingConfigTaskStatus();
      }, 5000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [configTaskId, configTaskApi]);

  //Ping for check task status
  useEffect(() => {
    if (checkTaskId) {
      const pingCheckTaskStatus = async () => {
        try {
          if (checkTask) {
            // Ping the API to check if the task is finished
            const response = await checkTask.resultCheckApi(checkTaskId!);

            if (response.data && Object.keys(response.data).length > 0) {
              // If the task is finished, clear the interval
              clearInterval(intervalId);
              // Handle the results data
              setCheckResponse(response.data);
              //Separate the infoTeste array in rows with n columns
              buildSummaryInfoTest(response.data.infoTest, 2);

              setLoading(false);
            }
          }
        } catch (error) {
          handleError({ error, setError });
          clearInterval(intervalId);
          setLoading(false);
        }
      };

      // Set up a periodic ping every 5 seconds to check if the Config Task is already finished and get the result
      const intervalId = setInterval(async () => {
        // Check task status initially
        pingCheckTaskStatus();
      }, 5000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [checkTaskId, checkTask]);

  //Change the Config API for the option selected
  useEffect(() => {
    if (activationSelected === ActivationType.ROUTER) {
      setConfigTaskApi({ configApi: lpRouterConfigRequest, resultConfigApi: lpRouterResultConfigRequest, certificationTask: { certificationApi: configurationCheckRequest, resultCertificationApi: configurationResultCheckRequest } });
    }

  }, [activationSelected]);

  //Change the Check API for the option selected
  useEffect(() => {
    if (diagnosticSelected === DiagnosticType.SWT) {
      setCheckTask({ checkApi: swtCheckRequest, resultCheckApi: swtResultCheckRequest });
    } else if (diagnosticSelected === DiagnosticType.OLT) {
      setCheckTask({ checkApi: oltCheckRequest, resultCheckApi: oltResultCheckRequest });
    } else if (diagnosticSelected === DiagnosticType.Prog) {
      setCheckTask({ checkApi: programmingCheckRequest, resultCheckApi: programmingResultCheckRequest });
    } else if (diagnosticSelected === DiagnosticType.Conf) {
      setCheckTask({ checkApi: configurationCheckRequest, resultCheckApi: configurationResultCheckRequest });
    } else if (diagnosticSelected === DiagnosticType.Duplicity) {
      setCheckTask({ checkApi: duplicityCheckRequest, resultCheckApi: duplicityResultCheckRequest });
    }

  }, [diagnosticSelected]);

  const handleTabButtonClick = (buttonType: MainButton) => {
    if (buttonType === MainButton.Config || buttonType === MainButton.Check) {
      if (formRef.current) {
        const isValid = formRef.current.reportValidity(); // Validate all form fields
        if (isValid) {
          setWarning('');
          if (tracking) setTracking(undefined);
          setActiveButtonId(buttonType);
        }
      }
    } else if (buttonType === MainButton.Historic) {
      console.log('Consultar Histórico');
      setActiveButtonId(buttonType);
    } else {
      setWarning('');
      if (tracking) setTracking(undefined);
      setActiveButtonId(buttonType);
    }

  };

  const handleActivationSelection = (e: ChangeEvent<HTMLInputElement>) => {
    // setIsFormValid(false);
    // if (submitButtonRef.current) {
    //   submitButtonRef.current.click();
    // }
    setActivionSelected(e.target.value);
  };

  const handleDiagnosticSelection = (e: ChangeEvent<HTMLInputElement>) => {
    // setIsFormValid(false);
    // if (submitButtonRef.current) {
    //   submitButtonRef.current.click();
    // }
    setDiagnosticSelected(e.target.value);
  };

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    // setIsFormValid(true);
    if (formRef.current && formRef.current.reportValidity()) {
      console.log('Formulário válido');
    }
  }

  const handleInputChangeLinkPrincipalForm = (value: string | number, key: string) => {
    // setIsFormValid(false);
    handleInputChange(value, `linkPrincipalForm.${key}`, setLpData);
  };

  const handleInputChangeCheckParamsForm = (value: string | number, key: string) => {
    // setIsFormValid(false);
    handleInputChange(value, `checkParams.${key}`, setLpData);
  };

  const handleInputChangeGponForm = (value: string | number, key: string) => {
    // setIsFormValid(false);
    handleInputChange(value, `tech.gpon.${key}`, setLpData);
  };

  const handleInputChangePrapsForm = (value: string | number, key: string) => {
    // setIsFormValid(false);
    handleInputChange(value, `tech.praps.${key}`, setLpData);
  };

  const handleInputChangeVpnForm = (value: string | number, key: string) => {
    // setIsFormValid(false);
    handleInputChange(value, `service.vpn.scriptsQosRouterForm.${key}`, setLpData);
  };

  const handleConfigButtonClick = async () => {
    try {
      setError('');
      setLoading(true);
      setConfigResponse(undefined);
      setSummaryInfoTest(undefined);
      if (configTaskApi) {
        const res = await configTaskApi.configApi(lpData);
        // console.log(res);
        if (res.data) {
          setConfigTaskId(res.data.id_result);
          setConfigProduct(res.data.product);
        }
      }
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  const handleCheckButtonClick = async () => {
    try {
      setError('');
      setLoading(true);
      setCheckResponse(undefined);
      setSummaryInfoTest(undefined);
      if (checkTask) {
        const res = await checkTask.checkApi(lpData);
        // console.log(res);
        if (res.data) {
          setCheckTaskId(res.data.id_result);
        }
      }
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  const handleTrackingButtonClick = async (idVantive: number) => {
    try {
      setError("");
      setTracking(undefined);
      setLoading(true);
      const resp = await trackingIatListRequest(idVantive);
      // console.log(resp.data);
      if (resp.data && resp.data.length > 0) {
        setTracking(resp.data[0]);
      } else {
        setWarning("Nenhuma task encontrada");
      }
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };

  const buildSummaryInfoTest = (infoTest: InfoTest[], columns: number) => {
    let summaryInfo = infoTest.reduce(function (rows: any[], key: any, index: number) {
      return (index % columns === 0 ? rows.push([key])
        : rows[rows.length - 1].push(key)) && rows;
    }, []);
    setSummaryInfoTest(summaryInfo);
  };

  return (
    <Box>
      <Stack sx={{ width: '100%' }} spacing={2}>
        {tipoRede === TipoRede[2] && (<Alert severity="warning" variant='outlined'>Esse ID Vantive possui rede FUSION, qualquer problema nas operações entrem em contato com o Centro de Operações.</Alert>)}
        {isStar ? (<Alert severity="warning" variant='outlined'>Consultando a base STAR, não é possível editar os campos.</Alert>)
          : (<Alert severity="warning" variant='outlined'>Consultando a base IAT.</Alert>)}
      </Stack>
      <Box
        sx={{
          '& .MuiTextField-root': { marginRight: '0.5em', marginBottom: '0.5em', width: '20%' },
        }}
      >
        {!config && !check && (
          <Box
            marginTop={'0.5em'}
          >
            <TextField
              select
              label='Ativação'
              value={activationSelected}
              helperText='Selecione o tipo de Ativação'
              onChange={handleActivationSelection}
            >
              {(Object.keys(ActivationType) as (keyof typeof ActivationType)[]).map((key) => (
                <MenuItem key={key} value={ActivationType[key]}>
                  {ActivationType[key]}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label='Diagnostico'
              value={diagnosticSelected}
              helperText='Selecione o tipo de Diagnóstico'
              onChange={handleDiagnosticSelection}
            >
              {(Object.keys(DiagnosticType) as (keyof typeof DiagnosticType)[]).map((key) => (
                <MenuItem key={key} value={DiagnosticType[key]}>
                  {DiagnosticType[key]}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
      </Box>
      <Box
        marginTop={2}>
        {buttons.map((button) => {
          let renderButton = true;
          if (button.type === MainButton.Config) {
            renderButton = !!configTaskApi;
            // button.disabled = !isFormValid;
          }
          if (button.type === MainButton.Check) {
            renderButton = !!checkTask;
            // button.disabled = !isFormValid;
          }
          if (renderButton) {
            return (
              <Button
                key={button.id}
                variant={activeButtonId === button.type ? "contained" : "outlined"}
                className={`${styles.main_button} ${activeButtonId === button.type ? styles.active : ""}`}
                onClick={() => handleTabButtonClick(button.type)}
                disabled={button.disabled}
              >
                {button.label}
              </Button>
            );
          } else {
            return <></>;
          }
        })}
      </Box>
      <Box marginTop={2}>
        {activeButtonId === MainButton.Ordem && (
          // Ordem Section
          <Grid container spacing={2} >
            <Grid item xs={8} >
              <Card className={styles.card}>
                <CardContent>
                  {/* Margin and width of all TextField inside the Box component */}
                  <Box
                    component='form'
                    ref={formRef}
                    onSubmit={handleSubmit}
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '30%' },
                    }}>
                    {/* Link Principal Form Section*/}
                    <Typography variant='h6' gutterBottom>Link Principal</Typography>
                    {isStar ? (
                      <DynamicView
                        data={lpData.linkPrincipalForm}
                        labelMap={customInputMainForm}
                      />
                    ) : (
                      <DynamicForm
                        data={lpData.linkPrincipalForm}
                        labelMap={customInputMainForm}
                        handleInputChange={handleInputChangeLinkPrincipalForm}
                      />
                    )}

                    {lpData.checkParams && isStar && (
                      <DynamicView
                        data={lpData.checkParams}
                        labelMap={customLabelsCheckParams}
                      />
                    )}
                    {lpData.checkParams && !isStar && (
                      <DynamicForm
                        data={lpData.checkParams}
                        labelMap={customLabelsCheckParams}
                        handleInputChange={handleInputChangeCheckParamsForm}
                      />
                    )}
                    <Typography variant='h6' gutterBottom>Tecnologia</Typography>
                    {/* Technology Section */}
                    {lpData.tech && (
                      <>
                        <div>
                          <TextField
                            id="select-tecnology"
                            select
                            label="Tecnologia"
                            variant='outlined'
                            required
                            value={technologySelected}
                            InputProps={{ readOnly: isStar }}
                            onChange={(e) => setTechnologySelected(e.target.value)}
                          >
                            {Object.values(Tech).map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        {/* Forms based on technology */}
                        {technologySelected === Tech.Gpon && isStar &&
                          // GPON Section
                          <DynamicView
                            data={lpData.tech.gpon}
                            labelMap={customLabelsGpon}
                          />
                        }
                        {technologySelected === Tech.Gpon && !isStar &&
                          // GPON Section
                          <DynamicForm
                            data={lpData.tech.gpon}
                            labelMap={customLabelsGpon}
                            handleInputChange={handleInputChangeGponForm}
                          />
                        }
                        {technologySelected === Tech.Praps && isStar &&
                          // PRAPS Section
                          <DynamicView
                            data={lpData.tech.praps}
                            labelMap={customLabelsPraps}
                          />
                        }
                        {technologySelected === Tech.Praps && !isStar &&
                          // PRAPS Section
                          <DynamicForm
                            data={lpData.tech.praps}
                            labelMap={customLabelsPraps}
                            handleInputChange={handleInputChangePrapsForm}
                          />
                        }
                      </>
                    )}
                    <Typography variant='h6' gutterBottom>Scripts QOS Router</Typography>
                    {lpData.service.vpn.scriptsQosRouterForm && isStar &&
                      // VPN Section
                      <DynamicView
                        data={lpData.service.vpn.scriptsQosRouterForm}
                        labelMap={customLabelsVpn}
                      />
                    }
                    {lpData.service.vpn.scriptsQosRouterForm && !isStar &&
                      // VPN Section
                      <DynamicForm
                        data={lpData.service.vpn.scriptsQosRouterForm}
                        labelMap={customLabelsVpn}
                        handleInputChange={handleInputChangeVpnForm}
                      />
                    }
                    {/* <Button type='submit' className='button' ref={submitButtonRef} sx={{ display: 'none' }}>Validar Dados</Button> */}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            {/* Card info Section */}
            <Grid item xs={4} >
              <Card className={styles.card}>
                <CardContent>
                  <Box
                    className={styles.params}
                    sx={{
                      '& hr': {
                        my: 1,
                      },
                    }}
                  >
                    <div style={{ color: '#8083A3' }}>ID Vantive</div>
                    <span>{lpData.idVantive}</span>
                    <Divider flexItem />
                    <div style={{ color: '#8083A3' }}>Cliente</div>
                    <span>{lpData.cliente}</span>
                    <Divider flexItem />
                    <div style={{ color: '#8083A3' }}>Número LP</div>
                    <span>{lpData.numeroLp}</span>
                    <Divider flexItem />
                    <div style={{ color: '#8083A3' }}>CNL</div>
                    <span>{lpData.cnl}</span>
                    <Divider flexItem />
                    <div style={{ color: '#8083A3' }}>Serviço</div>
                    <span>{lpData.service.name}</span>
                    <Divider flexItem />
                    <div style={{ color: '#8083A3' }}>Tipo de Rede</div>
                    <span>{tipoRede}</span>
                    <Divider flexItem />
                    <div style={{ color: '#8083A3' }}>Status</div>
                    <span>{lpData.status}</span>
                    <Divider flexItem />
                    {tracking && (
                      <>
                        <Button
                          sx={{ marginTop: '1em' }}
                          variant='contained'
                          className='button'
                          onClick={() => handleTrackingButtonClick(lpData.idVantive)}
                          startIcon={<TroubleshootIcon />}
                          disabled={loading}
                        >
                          {loading ? 'Atualizando...' : 'Atualizar Tracking'}
                        </Button>
                        <TaskTrackingDetailCard tracking={tracking} />
                      </>
                    )}
                    {warning && (
                      <p className='warning'>{warning}</p>
                    )}
                    {!tracking && (
                      <>
                        <Button
                          variant='contained'
                          className='button'
                          onClick={() => handleTrackingButtonClick(lpData.idVantive)}
                          startIcon={<TroubleshootIcon />}
                          disabled={loading}
                        >
                          {loading ? 'Verificando...' : 'Verificar Tracking'}
                        </Button>
                      </>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
        {activeButtonId === MainButton.Config && (
          <Grid container spacing={2} >
            <Grid item xs={8} >
              <Card className={styles.card}>
                <CardContent>
                  <Box display={'flex'} justifyContent={'center'}>
                    <Button
                      variant='contained'
                      className='button'
                      onClick={handleConfigButtonClick}
                      startIcon={<PlayCircleFilledIcon />}
                      style={{ display: !configResponse ? 'inherit' : 'none' }}
                      disabled={loading}
                    >
                      {loading ? `Configurando...` : `Iniciar Configuração`}
                    </Button>
                  </Box>
                  {error && <p className='error'>{error}</p>}
                  {configResponse && configProduct && (
                    <IatResultDetail result={configResponse} handleReconfig={handleConfigButtonClick} manualTask={{ taskType: 'config', taskProduct: configProduct }} />
                  )}
                  {loading && (
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
                      open={loading}
                    >
                      {/* <CircularProgress color="inherit" /> */}
                      {/* <CircularWithValueLabel maxSecondsTime={180} interval={5000} /> */}
                      <CircularProgressIndeterminateWithLabel
                        maxSecondsTime={180}
                        descriptions={['Processo em andamento...', 'Processo demorando mais que o normal...', 'Provável falha na consulta por timeout...']}
                        showTimer={true}
                      />
                    </Backdrop>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} >
              <Card className={styles.sticky_card}>
                <CardContent>
                  <Box sx={{
                    '& hr': {
                      my: 1,
                    },
                  }}>
                    <Box className={styles.params}
                      sx={{
                        '& hr': {
                          mx: 0.5,
                        },
                      }}
                    >
                      <div style={{ color: '#8083A3' }}>ID Vantive</div>
                      <span>{lpData.idVantive}</span>
                    </Box>
                    {configResponse && (
                      <>
                        <Divider />
                        <Box className={styles.params}
                          sx={{
                            '& hr': {
                              mx: 0.5,
                            },
                          }}
                        >
                          <div style={{ color: '#8083A3' }}>ID Result</div>
                          <span>
                            {configResponse._id}
                            <CopyToClipboard text={configResponse._id}>
                              <IconButton title='Copiar'>
                                <CopyAllIcon />
                              </IconButton>
                            </CopyToClipboard>
                          </span>
                        </Box>
                      </>
                    )}
                    {summaryInfoTest && (
                      <>
                        <Divider />
                        <SummaryInfo summaryTestInfo={summaryInfoTest} />
                        {configTaskApi &&
                          configResponse && configResponse.idStatusResult !== 2 &&
                          configProduct && (
                            <>
                              <Divider />
                              <Box display={'flex'} justifyContent={'center'}>
                                <IatFinishTaskSnackbar
                                  open={openFinishTaskSnackbar}
                                  setOpen={setOpenFinishTaskSnackbar}
                                  iatInfo={lpData}
                                  product={configProduct}
                                  certification={{ certificationApi: configTaskApi.certificationTask.certificationApi, resultCertificationApi: configTaskApi.certificationTask.resultCertificationApi }}
                                />
                              </Box>
                            </>
                          )}
                      </>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
        {activeButtonId === MainButton.Check && (
          <Grid container spacing={2} >
            <Grid item xs={8} >
              <Card className={styles.card}>
                <CardContent>
                  <Box display={'flex'} justifyContent={'center'}>
                    <Button
                      variant='contained'
                      className='button'
                      onClick={handleCheckButtonClick}
                      startIcon={<PlayCircleFilledIcon />}
                      style={{ display: !checkResponse ? 'inherit' : 'none' }}
                      disabled={loading}
                    >
                      {loading ? `Validando...` : `Iniciar Validação`}
                    </Button>
                  </Box>
                  {error && <p className='error'>{error}</p>}
                  {checkResponse && (
                    <IatResultDetail result={checkResponse} handleReconfig={handleCheckButtonClick} />
                  )}
                  {loading && (
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
                      open={loading}
                    >
                      {/* <CircularProgress color="inherit" /> */}
                      {/* <CircularWithValueLabel maxSecondsTime={30} interval={5000} /> */}
                      <CircularProgressIndeterminateWithLabel
                        maxSecondsTime={180}
                        descriptions={['Processo em andamento...', 'Processo demorando mais que o normal...', 'Provável falha na consulta por timeout...']}
                        showTimer={true}
                      />                    </Backdrop>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} >
              <Card className={styles.sticky_card}>
                <CardContent>
                  <Box sx={{
                    '& hr': {
                      my: 1,
                    },
                  }}>
                    <Box className={styles.params}
                      sx={{
                        '& hr': {
                          mx: 0.5,
                        },
                      }}
                    >
                      <div style={{ color: '#8083A3' }}>ID Vantive</div>
                      <span>{lpData.idVantive}</span>
                    </Box>
                    {checkResponse && (
                      <>
                        <Divider />
                        <Box className={styles.params}
                          sx={{
                            '& hr': {
                              mx: 0.5,
                            },
                          }}
                        >
                          <div style={{ color: '#8083A3' }}>ID Result</div>
                          <span>
                            {checkResponse._id}
                            <CopyToClipboard text={checkResponse._id}>
                              <IconButton title='Copiar'>
                                <CopyAllIcon />
                              </IconButton>
                            </CopyToClipboard>
                          </span>
                        </Box>
                      </>
                    )}
                    {summaryInfoTest && (
                      <>
                        <Divider />
                        <SummaryInfo summaryTestInfo={summaryInfoTest} />
                      </>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
        {activeButtonId === MainButton.Historic && (
          <Card className={styles.card}>
            <CardContent>
              <IatHistoricActivities idVantive={lpData.idVantive} />
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default IatLegacyDisplay;
